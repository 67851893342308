import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { JobDetailApplicationState, applicationReducer } from './feature/application/application.reducer';
import { JobDetailBrandingState, brandingReducer } from './feature/branding/branding.reducer';
import { JobDetailGeneralState, generalReducer } from './feature/general/general.reducer';
import {
  JobDetailRecruitmentProcessState,
  recruitmentProcessReducer,
} from './feature/recruitment-process/recruitment-process.reducer';
import { JOB_DETAIL_FEATURE_KEY, JobDetailState, jobDetailReducer } from './job-detail.reducer';

export interface State {
  GLOBAL: JobDetailState;
  GENERAL: JobDetailGeneralState;
  BRANDING: JobDetailBrandingState;
  RECRUITMENT_PROCESS: JobDetailRecruitmentProcessState;
  APPLICATION: JobDetailApplicationState;
}

export const reducers: ActionReducerMap<State> = {
  GLOBAL: jobDetailReducer,
  GENERAL: generalReducer,
  BRANDING: brandingReducer,
  RECRUITMENT_PROCESS: recruitmentProcessReducer,
  APPLICATION: applicationReducer,
};

export const selectJobDetailState = createFeatureSelector<State>(JOB_DETAIL_FEATURE_KEY);
