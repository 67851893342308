import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'wh-s-backdrop',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: [
    `
      :host {
        display: block;
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 10001;
        background-color: black;
        opacity: 0.5;
        top: 0;
        left: 0;
      }
    `,
  ],
})
export class BackdropComponent {
  @Input()
  public isOpen = false;

  @Output()
  public backdropClick = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public onBackdropClick(mouseEvent: MouseEvent): void {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();

    this.backdropClick.emit();
  }
}
