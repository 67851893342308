import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthApi, EmployerApi, TranslocoApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth/auth-api.service';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class EmployerApiService extends AuthApiService<EmployerApi.EmployerLoginResponseDto> {
  public readonly afterLogoutRoute = '/auth';

  constructor(httpClient: HttpClient, @Inject(BASE_URL_TOKEN) baseUrl: string) {
    super(`${baseUrl}/employers`, httpClient);
  }

  public getMany(
    pageSize: number,
    currentPage: number,
    searchTerm: string,
    employerId?: string,
  ): Observable<PaginateResult<EmployerApi.Employer>> {
    return this.httpClient.get<PaginateResult<EmployerApi.Employer>>(
      `${this.url}${searchTerm ? '?search-term=' + searchTerm : ''}`,
      {
        params: employerId
          ? new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize).set('employerId', employerId)
          : new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
      },
    );
  }

  public getManyByCompanyId(companyId: string): Observable<EmployerApi.Employer[]> {
    return this.httpClient.get<EmployerApi.Employer[]>(`${this.url}/company/${companyId}`);
  }

  public getProfile(employerId: string): Observable<EmployerApi.EmployerProfile> {
    return this.httpClient.get<EmployerApi.EmployerProfile>(`${this.url}/profile`, {
      params: new HttpParams().set('employerId', employerId),
    });
  }

  public updateProfile(employerProfile: EmployerApi.EmployerProfile): Observable<EmployerApi.EmployerProfile> {
    return this.httpClient.put<EmployerApi.EmployerProfile>(`${this.url}/profile`, employerProfile);
  }

  public changePassword(changePassword: AuthApi.ChangePassword): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/change-password`, changePassword);
  }

  public changeEmail(changeEmail: AuthApi.ChangeEmail): Observable<string> {
    return this.httpClient.put(`${this.url}/change-email`, changeEmail, { responseType: 'text' });
  }

  public changePreferredCommunicationLanguage(locale: TranslocoApi.Locale): Observable<string> {
    return this.httpClient.put(
      `${this.url}/change-preferred-communication-language`,
      { locale },
      { responseType: 'text' },
    );
  }

  public create(employerDto: EmployerApi.CreateEmployer): Observable<EmployerApi.Employer> {
    return this.httpClient.post<EmployerApi.Employer>(`${this.url}`, employerDto);
  }

  public update(updateEmployer: EmployerApi.UpdateEmployer): Observable<EmployerApi.Employer> {
    return this.httpClient.put<EmployerApi.Employer>(`${this.url}`, updateEmployer);
  }

  public addAvatar(imageFile: File, employerId: string): Observable<string> {
    const formData = new FormData();
    formData.append('file', imageFile);

    return this.httpClient.post(`${this.url}/avatar`, formData, {
      params: new HttpParams().set('employerId', employerId),
      responseType: 'text',
    });
  }

  public deleteAvatar(employerId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/avatar`, {
      params: new HttpParams().set('employerId', employerId),
    });
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }

  public register(employerRegisterDto: EmployerApi.RegisterEmployer): Observable<EmployerApi.EmployerLoginResponseDto> {
    return this.httpClient.post<EmployerApi.EmployerLoginResponseDto>(`${this.url}/register`, employerRegisterDto);
  }

  public createHelpTicket(message: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/help-ticket`, { message });
  }

  // noinspection TypeScriptValidateTypes
  // eslint-disable-next-line
  public getV2InfoPdf(): Observable<any> {
    const headers = new HttpHeaders();

    return this.httpClient.get(`${this.url}/v2-info-pdf`, {
      headers,
      responseType: 'blob',
    });
  }
}
