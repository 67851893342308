<header>
  <section class="left">
    <div class="icon-container">
      <i-feather (click)="toggleDrawer.emit()" name="menu"></i-feather>
    </div>

    <div class="logo-container" (click)="logoClick.emit()">
      <img class="workerhero-logo-lg" src="/assets/image/workerhero_logo.png" alt="" />
    </div>
  </section>

  <section class="middle"></section>

  <section class="right">
    <wh-language-switch-new [languages]="languages" (languageClicked)="languageClicked.emit($event)" />

    <div class="separator"></div>

    @if (role) {
      <wh-s-role-chip [role]="role" />
    }

    <wh-s-avatar-wrapper
      class="{{ isProfileClickAllowed ? '' : 'profile-click-disabled' }}"
      [username]="username"
      [avatarSrc]="avatarSrc"
      (profileClick)="profileClick.emit()"
    />
  </section>
</header>
