import { createSelector } from '@ngrx/store';
import { selectJobDetailState } from '../../job-detail.state';

export const selectJobDetailBrandingFeature = createSelector(selectJobDetailState, state => state.BRANDING);

export const selectJobDetailBrandingReadonly = createSelector(
  selectJobDetailBrandingFeature,
  state => state.readonly.branding,
);

export const selectJobDetailBrandingWriteable = createSelector(
  selectJobDetailBrandingFeature,
  state => state.write.branding,
);

export const selectJobDetailBrandingGeneratedDescription = createSelector(
  selectJobDetailBrandingFeature,
  state => state.preview.generatedContent,
);

export const selectJobDetailBrandingWrite = createSelector(selectJobDetailBrandingFeature, state => state.write);

export const selectJobDetailPreviewState = createSelector(selectJobDetailBrandingFeature, state => state.preview);

export const selectIsAiAssistantLoading = createSelector(
  selectJobDetailBrandingFeature,
  state => state.preview.isLoading,
);

export const selectJobDescriptionTextVariants = createSelector(
  selectJobDetailBrandingFeature,
  state => state.write.branding?.description,
);

export const selectShortJobDescriptionTextVariants = createSelector(
  selectJobDetailBrandingFeature,
  state => state.write.branding?.shortDescription,
);

export const selectIsBrandingFormTouched = createSelector(
  selectJobDetailBrandingFeature,
  state => state.write.hasBeenTouched,
);
