import { createAction, props } from '@ngrx/store';
import { IntegrationApi, JobApi } from '@web/shared/data-access/model';

enum ActionType {
  SET_JOB_ID = '[Job Detail] Set job ID',
  SET_COMPANY_ID = '[Job Detail] Set company ID',
  SET_ACTIVE_TAB = '[Job Detail] Set active tab',
  REMOVE_JOB_DETAIL = '[Job Detail] Remove job detail',
  DELETE_JOB = '[Job Detail] Delete job',
  DUPLICATE_JOB = '[Job Detail] Duplicate job',
  ARCHIVE_JOB = '[Job Detail] Archive job',
  TOGGLE_LOADING = '[Job Detail] Toggle loading',
  TOGGLE_EDIT_MODE = '[Job Detail] Toggle edit mode',
  TOGGLE_READONLY_MODE = '[Job Detail] Toggle readonly mode',
  UPDATE_JOB_DETAIL = '[Job Detail] Execute update',
  SET_READONLY_MODE = '[Job Detail] Set job detail readonly mode',
  SET_EDIT_MODE = '[Job Detail] Set job detail edit mode',
  SET_IMAGES = '[Job Detail] Set job detail Banner & Avatar image',
  SYNC_TO_JOB_BOARD = '[Job Detail] Sync to job board',
  SET_INTEGRATIONS = '[Job Detail] Set integrations',
}

export const setJobId = createAction(ActionType.SET_JOB_ID, props<{ jobId: string }>());

export const setCompanyId = createAction(ActionType.SET_COMPANY_ID, props<{ companyId: string }>());

export const setActiveTabAction = createAction(ActionType.SET_ACTIVE_TAB, props<{ activeTab: JobApi.JobDetailTab }>());

export const removeJobDetail = createAction(ActionType.REMOVE_JOB_DETAIL);

export const setIsJobDetailLoading = createAction(ActionType.TOGGLE_LOADING, props<{ isLoading: boolean }>());

export const setIntegrations = createAction(
  ActionType.SET_INTEGRATIONS,
  props<{ integrations: IntegrationApi.KomboIntegration[] }>(),
);

export const toggleEditMode = createAction(ActionType.TOGGLE_EDIT_MODE);

export const toggleReadonlyMode = createAction(ActionType.TOGGLE_READONLY_MODE);

export const updateJobDetail = createAction(ActionType.UPDATE_JOB_DETAIL);

export const setReadonlyMode = createAction(ActionType.SET_READONLY_MODE, props<{ isReadonly: boolean }>());

export const setEditMode = createAction(ActionType.SET_EDIT_MODE, props<{ isEdit: boolean }>());

export const setCompanyImages = createAction(ActionType.SET_IMAGES, props<{ avatarUrl: string; bannerUrl: string }>());

export const syncToJobBoard = createAction(ActionType.SYNC_TO_JOB_BOARD);

export const deleteJob = createAction(ActionType.DELETE_JOB);

export const duplicateJob = createAction(ActionType.DUPLICATE_JOB);

export const archiveJob = createAction(ActionType.ARCHIVE_JOB);
