import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmployerApi } from '@web/shared/data-access/model';
import { AuthViewModel } from '@web/web/core/auth/data-access';
import { EmployerApiService } from '@web/web/shared/data-access/api';
import { EmployerLocalStorageService } from '../storage/employer-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EmployerAuthViewModel extends AuthViewModel<EmployerApi.EmployerLoginResponseDto> {
  public readonly LOGOUT_ROUTE = 'auth';

  constructor(
    employerLocalStorageService: EmployerLocalStorageService,
    router: Router,
    employerApiService: EmployerApiService,
  ) {
    super(employerLocalStorageService, router, employerApiService);
  }
}
