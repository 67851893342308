import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmployerApi } from '@web/shared/data-access/model';
import { AuthGuard } from '@web/web/core/auth/data-access';
import { EmployerAuthViewModel } from '../viewmodel/employer-auth.viewmodel';
import { EmployerLoginViewModel } from '../viewmodel/employer-login.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class EmployerAuthGuard extends AuthGuard<EmployerApi.EmployerLoginResponseDto> {
  constructor(
    employerAuthViewModel: EmployerAuthViewModel,
    employerLoginViewModel: EmployerLoginViewModel,
    router: Router,
  ) {
    super(employerAuthViewModel, employerLoginViewModel, router);
  }
}
