import { Routes } from '@angular/router';
import { EmployerAuthGuard, EmployerLoggedInGuard } from '@web/web/employer/core/auth/data-access';
import { VerifyAccountComponent } from '@web/web/employer/core/auth/feature/verify-account';
import { NotFoundComponent } from 'web/shared/ui/not-found';
import { LayoutComponent } from './layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [EmployerAuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: async () => (await import('web/employer/domain/dashboard/feature/shell')).DashboardShellModule,
      },
      {
        path: 'profile',
        loadChildren: async () =>
          (await import('@web/web/employer/domain/profile/feature/shell')).EmployerProfileShellModule,
      },
      {
        path: 'users',
        loadChildren: async () => (await import('@web/web/employer/domain/user/feature/shell')).UserShellModule,
      },
      {
        path: 'recruiting',
        loadChildren: async () => (await import('@web/web/employer/domain/job/feature/shell')).JobShellModule,
      },
      {
        path: 'company',
        loadChildren: async () => (await import('web/employer/domain/company/feature/shell')).CompanyProfileShellModule,
      },
      {
        path: 'integrations',
        loadChildren: async () =>
          (await import('web/employer/domain/integration/feature/shell')).IntegrationShellModule,
      },
    ],
  },
  {
    path: 'verify/:userId',
    component: VerifyAccountComponent,
  },
  {
    path: 'auth',
    canActivate: [EmployerLoggedInGuard],
    loadChildren: async () => (await import('@web/web/employer/core/auth/feature/shell')).AuthFeatureShellModule,
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
