import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, RouterOutlet, provideRouter, withComponentInputBinding } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthApi } from '@web/shared/data-access/model';
import { CLIENT_INJECTION_TOKEN, ErrorInterceptor, LoadingInterceptor } from '@web/web/core/auth/data-access';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { EmployerAuthInterceptor, EmployerRefreshTokenInterceptor } from '@web/web/employer/core/auth/data-access';
import { HelpDialogComponent } from '@web/web/employer/core/help/feature/dialog';
import { BASE_URL_TOKEN, HERE_MAPS_API_KEY_TOKEN, HERE_MAPS_APP_ID_TOKEN } from '@web/web/shared/data-access/api';
import { AlertContainerComponent } from '@web/web/shared/ui/alert-container';
import { BadgeComponent } from '@web/web/shared/ui/badge';
import { ButtonModule } from '@web/web/shared/ui/button';
import { FeatherIconModule } from '@web/web/shared/ui/feather-icon';
import { ModalModule } from '@web/web/shared/ui/modal';
import { NavSideModule } from '@web/web/shared/ui/nav-side';
import { PanelTopModule } from '@web/web/shared/ui/panel-top';
import { ProgressBarComponent } from '@web/web/shared/ui/progress-bar';
import { SidenavModule } from '@web/web/shared/ui/sidenav';
import { TextVariantPipe } from '@web/web/shared/util/pipes';
import { SocketIoModule } from 'ngx-socket-io';
import { GlobalStoreModule } from 'web/shared/data-access/global';
import { JobDetailStoreModule } from 'web/shared/data-access/job-detail';
import { SidenavStoreModule } from 'web/shared/data-access/sidenav';
import { FileViewerComponent } from 'web/shared/ui/file-viewer';
import { LayoutComponent } from './layout/layout.component';
import { routes } from './routes';

@NgModule({
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', bindToComponentInputs: true }),
    FeatherIconModule,
    WebCoreI18nModule,
    SidenavModule,
    AlertContainerComponent,
    BadgeComponent,
    ProgressBarComponent,
    ModalModule,
    NavSideModule,
    PanelTopModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionSerializability: true,
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    // StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot(),
    SocketIoModule.forRoot({
      url: `${process.env['BASE_URL']}`.replace('/api', ''),
    }),
    JobDetailStoreModule,
    HelpDialogComponent,
    FileViewerComponent,
    ButtonModule,
    SidenavStoreModule,
    GlobalStoreModule,
  ],
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    { provide: CLIENT_INJECTION_TOKEN, useValue: AuthApi.Client.EMPLOYER_APP },
    DecimalPipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: BASE_URL_TOKEN,
      useValue: `${process.env['BASE_URL']}`,
    },
    {
      provide: HERE_MAPS_APP_ID_TOKEN,
      useValue: `${process.env['HERE_MAPS_APP_ID']}`,
    },
    {
      provide: HERE_MAPS_API_KEY_TOKEN,
      useValue: `${process.env['HERE_MAPS_API_KEY']}`,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmployerAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmployerRefreshTokenInterceptor,
      multi: true,
    },
    TextVariantPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class WebEmployerShellModule {}
