import { createReducer, on } from '@ngrx/store';
import { AssessmentApi } from '@web/shared/data-access/model';
import * as JobDetailApplicationActions from './application.action';
import { ApplicationReadonly } from './application.interface';

export type JobDetailApplicationState = {
  readonly: ApplicationReadonly;
};

const initialJobDetailApplicationState: JobDetailApplicationState = {
  readonly: {
    assessment: new Map<string, AssessmentApi.ApplicantParticipation[]>(),
    applications: {
      data: [],
      currentPage: 1,
      total: 0,
    },
    isLoading: false,
    columns: [
      {
        title: 'Applicant',
        type: 'string',
        key: 'applicant',
        firstChildKey: 'email',
      },
      {
        title: 'Job Location',
        type: 'string',
        key: 'job',
        firstChildKey: 'city',
      },
      {
        title: 'Hidden',
        type: 'string',
        key: 'isHidden',
      },
      {
        title: 'Current step',
        type: 'string',
        key: 'currentStep',
        firstChildKey: 'title',
        additionalValues: ['noHireReason', 'abortApplicationReason'],
      },
      {
        title: 'Time in step',
        type: 'string',
        key: 'currentStep',
        firstChildKey: 'timeSpentInStep',
      },
      {
        title: 'Created',
        type: 'date',
        key: 'createdAt',
      },
      {
        title: 'Visible since',
        type: 'date',
        key: 'visibleDate',
      },
    ],
  },
};

export const applicationReducer = createReducer(
  initialJobDetailApplicationState,
  on(
    JobDetailApplicationActions.setJobDetailApplications,
    (state, { applications }): JobDetailApplicationState => ({
      ...state,
      readonly: {
        ...state.readonly,
        applications: {
          ...state.readonly.applications,
          data: applications,
        },
      },
    }),
  ),
  on(JobDetailApplicationActions.setAssessment, (state, { assessment }): JobDetailApplicationState => {
    const participationGroupMap = new Map<string, AssessmentApi.ApplicantParticipation[]>();

    assessment.forEach(participationAssessment => {
      if (participationGroupMap.has(participationAssessment.assessmentId)) {
        participationGroupMap.get(participationAssessment.assessmentId)?.push(participationAssessment);
      } else {
        participationGroupMap.set(participationAssessment.assessmentId, [participationAssessment]);
      }
    });

    return {
      ...state,
      readonly: {
        ...state.readonly,
        assessment: participationGroupMap,
        isLoading: false,
      },
    };
  }),
  on(
    JobDetailApplicationActions.setApplicationVisibility,
    (state, { applicationId, isHidden }): JobDetailApplicationState => {
      return {
        ...state,
        readonly: {
          ...state.readonly,
          applications: {
            ...state.readonly.applications,
            data: state.readonly.applications.data.map(application =>
              application.id === applicationId ? { ...application, isHidden } : { ...application },
            ),
          },
          isLoading: false,
        },
      };
    },
  ),
  on(
    JobDetailApplicationActions.removeFromStore,
    (state, { id }): JobDetailApplicationState => ({
      ...state,
      readonly: {
        ...state.readonly,
        applications: {
          ...state.readonly.applications,
          data: state.readonly.applications.data.filter(application => application.id !== id),
        },
        isLoading: false,
      },
    }),
  ),
  on(
    JobDetailApplicationActions.clearApplicationDataFromStore,
    (state): JobDetailApplicationState => ({
      ...state,
      readonly: {
        ...state.readonly,
        assessment: new Map<string, AssessmentApi.ApplicantParticipation[]>(),
        applications: {
          data: [],
          currentPage: 1,
          total: 0,
        },
        isLoading: false,
      },
    }),
  ),
);
