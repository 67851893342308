import { Injectable } from '@angular/core';
import { EmployerApi } from '@web/shared/data-access/model';
import { RefreshTokenInterceptor } from '@web/web/core/auth/data-access';
import { EmployerApiService } from '@web/web/shared/data-access/api';
import { EmployerAuthViewModel } from '../viewmodel/employer-auth.viewmodel';

@Injectable()
export class EmployerRefreshTokenInterceptor extends RefreshTokenInterceptor<EmployerApi.EmployerLoginResponseDto> {
  constructor(employerApiService: EmployerApiService, employerAuthViewModel: EmployerAuthViewModel) {
    super(employerApiService, employerAuthViewModel);
  }
}
