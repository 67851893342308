export enum ScreenSizeDf {
  mobileSmall = 'MOBILE_SMALL',
  mobile = 'MOBILE',
  tabletPortrait = 'TABLET_PORTRAIT',
  tabletLandscape = 'TABLET_LANDSCAPE',
  desktop = 'DESKTOP',
  desktopLarge = 'DESKTOP_LARGE',
}

export interface ScreenVariantDf {
  mobileSmall: number;
  mobile: number;
  tabletPortrait: number;
  tabletLandscape: number;
  desktop: number;
  desktopLarge: number;
}
