import { createSelector } from '@ngrx/store';
import { CompensationApi } from '@web/shared/data-access/model';
import { selectJobDetailState } from '../../job-detail.state';

export const selectJobDetailGeneralFeature = createSelector(selectJobDetailState, state => state.GENERAL);

export const selectJobDetailGeneralReadonly = createSelector(
  selectJobDetailGeneralFeature,
  state => state.readonly.initial,
);

export const selectJobDetailGeneralWrite = createSelector(selectJobDetailGeneralFeature, state => state.write);

export const selectJobDetailGeneralEditableV2 = createSelector(selectJobDetailGeneralFeature, state => ({
  ...state.write,
}));

export const selectIsGeneralFormTouched = createSelector(
  selectJobDetailGeneralFeature,
  state => state.write.hasBeenTouched,
);

export const selectListOfCompanies = createSelector(
  selectJobDetailGeneralFeature,
  state => state.readonly.companies.list,
);

export const selectIsAiAssistantLoadingInGeneral = createSelector(
  selectJobDetailGeneralFeature,
  state => state.preview.isLoading,
);

export const selectJobTitle = createSelector(
  selectJobDetailGeneralFeature,
  state => state.write.generalInfo?.jobTitle ?? [],
);

export const selectExternalJobIdWrite = createSelector(
  selectJobDetailGeneralFeature,
  state => state.write.externalJobId,
);

export const selectExternalJobIdReadonly = createSelector(
  selectJobDetailGeneralFeature,
  state => state.readonly.initial?.externalJobId,
);

export const selectPreviewStateGeneral = createSelector(selectJobDetailGeneralFeature, state => state.preview);

export const selectIsGeneralFormValid = createSelector(selectJobDetailGeneralFeature, state => {
  const { generalInfo, selectedEmploymentTypes, jobStatus, selectedCompany, selectedFeature, hasBeenTouched } =
    state.write;

  const isJobStatusValid = !!jobStatus;
  const isSelectedCompanyValid = !!selectedCompany;
  const isSelectedFeatureValid = !!selectedFeature;
  // const isExpDateValid = !!isExpirationDateValid;
  const isJobTitleValid = !!generalInfo?.jobTitle;
  const isJobTypeValid = !!generalInfo?.jobType;
  const isJobAddressValid = !!generalInfo?.jobAddress;
  const isJobEmploymentTypeValid = !!selectedEmploymentTypes.length;
  // const isJobCompensationsValid = isCompensationValid(compensationType);

  return (
    isJobStatusValid &&
    isSelectedCompanyValid &&
    isSelectedFeatureValid &&
    // isExpDateValid &&
    isJobTitleValid &&
    isJobTypeValid &&
    isJobAddressValid &&
    isJobEmploymentTypeValid &&
    // isJobCompensationsValid &&
    hasBeenTouched
  );
});

export const selectIsGeneralFormEmployerValid = createSelector(selectJobDetailGeneralFeature, state => {
  const { generalInfo, selectedEmploymentTypes, hasBeenTouched } = state.write;

  const isJobTitleValid = !!generalInfo?.jobTitle;
  const isJobTypeValid = !!generalInfo?.jobType;
  const isJobAddressValid = !!generalInfo?.jobAddress;
  const isJobEmploymentTypeValid = !!selectedEmploymentTypes.length;
  // const isJobCompensationsValid = isCompensationValid(compensationType);

  return (
    isJobTitleValid &&
    isJobTypeValid &&
    isJobAddressValid &&
    isJobEmploymentTypeValid &&
    // isJobCompensationsValid &&
    hasBeenTouched
  );
});

function isCompensationValid(compensation: CompensationApi.Compensation[]): boolean {
  //  Case where there is no added compensations
  if (!compensation.length) {
    return false;
  }

  const compensationThatIsNotFilledUp = compensation.find(compensation => {
    switch (compensation.compensationType) {
      case CompensationApi.CompensationType.SALARY:
        return !compensation.amountFrom || !compensation.currency || !compensation.interval;
      case CompensationApi.CompensationType.SALARY_RANGE:
        return !compensation.amountFrom || !compensation.amountTo || !compensation.currency || !compensation.interval;
      case CompensationApi.CompensationType.BONUS:
        return (
          !compensation.amountFrom || !compensation.currency || !compensation.bonusType || !compensation.bonusInterval
        );
      default:
        return compensation;
    }
  });

  return !compensationThatIsNotFilledUp;
}
