import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ApplicantApi } from '@web/shared/data-access/model';

@Injectable({
  providedIn: 'root',
})
export class FileViewerService {
  public isFileViewerOpen = false;

  public fileAssetUrl: string | null = null;
  public imageAssetUrl: SafeUrl | null = null;

  public open(assetUrl: string | SafeUrl | undefined, fileFormat: ApplicantApi.DocumentFileFormat): void {
    if (!assetUrl) {
      return;
    }

    this.isFileViewerOpen = true;

    //  If it's PDF it will open the file-viewer
    //  This can be extended by adding .docx or any other format in this if case
    if (fileFormat === ApplicantApi.DocumentFileFormat.PDF) {
      this.fileAssetUrl = assetUrl.toString();

      return;
    }

    //  Any other format, it will open the image-viewer
    this.imageAssetUrl = assetUrl;
  }

  public close(): void {
    this.isFileViewerOpen = false;

    this.fileAssetUrl = null;
    this.imageAssetUrl = null;
  }
}
