import { createReducer, on } from '@ngrx/store';
import * as SideNavActions from './sidenav.action';

export const SIDE_NAV_FEATURE_KEY = 'SIDE_NAV_FEATURE_KEY';

export type SideNavState = {
  isSideNavOpen: boolean;
};

const initialJobListState: SideNavState = {
  isSideNavOpen: true,
};

export const sidenavReducer = createReducer(
  initialJobListState,
  on(
    SideNavActions.toggleSidenavDrawerAction,
    (state): SideNavState => ({
      ...state,
      isSideNavOpen: !state.isSideNavOpen,
    }),
  ),
);
