import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wh-s-notification-count',
  template: `<span>{{ notificationCount }}</span>`,
  styles: [
    `
      /*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;500;700&display=swap');*/

      :host {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;

        & > span {
          background: #bccef2;
          border-radius: 2rem;
          padding: 5px;
          font-size: 11px;
          color: #4f80e2;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          pointer-events: none;
          user-select: none;
        }
      }

      :host.is-hidden {
        visibility: hidden;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class NotificationCountComponent implements OnInit {
  @Input()
  public notificationCount?: number;

  @HostBinding('class.is-hidden')
  public isComponentHidden = true;

  public ngOnInit(): void {
    this.isComponentHidden = !this.notificationCount;
  }
}
