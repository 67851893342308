import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmployerApi } from '@web/shared/data-access/model';
import { LoggedInGuard } from '@web/web/core/auth/data-access';
import { EmployerAuthViewModel } from '../viewmodel/employer-auth.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class EmployerLoggedInGuard extends LoggedInGuard<EmployerApi.EmployerLoginResponseDto> {
  protected constructor(employerAuthViewModel: EmployerAuthViewModel, router: Router) {
    super(employerAuthViewModel, router, 'dashboard');
  }
}
