import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { FeatherModule } from 'angular-feather';
import { NavSideItemComponent } from './nav-side-item.component';

@NgModule({
  imports: [CommonModule, FeatherModule, WebCoreI18nModule],
  declarations: [NavSideItemComponent],
  exports: [NavSideItemComponent],
})
export class NavSideItemModule {}
