import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ApplicationEffect } from './store/feature/application/application.effect';
import { BrandingEffect } from './store/feature/branding/branding.effect';
import { GeneralEffect } from './store/feature/general/general.effect';
import { RecruitmentProcessEffect } from './store/feature/recruitment-process/recruitment-process.effect';
import { JobDetailEffect } from './store/job-detail.effect';
import { JOB_DETAIL_FEATURE_KEY } from './store/job-detail.reducer';
import { reducers } from './store/job-detail.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(JOB_DETAIL_FEATURE_KEY, reducers),
    EffectsModule.forFeature([
      JobDetailEffect,
      GeneralEffect,
      BrandingEffect,
      RecruitmentProcessEffect,
      ApplicationEffect,
    ]),
  ],
})
export class JobDetailStoreModule {}
