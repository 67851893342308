import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyApi, EmployerApi, HereMapsApi, ProfessionalFieldApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { EmployerApiService } from '@web/web/shared/data-access/api';
import { DropdownOption } from '@web/web/shared/ui/dropdown';
import { confirmPasswordValidatorFunction } from '@web/web/shared/util/function';
import { take, tap } from 'rxjs';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';
import { strongPasswordValidator } from 'web/shared/util/validator';
import { EmployerAuthViewModel } from './employer-auth.viewmodel';

@Injectable()
export class RegisterViewModel {
  public employerFormGroup: FormGroup;
  public companyFormGroup: FormGroup;
  public step = 0;
  public professionalField: ProfessionalFieldApi.ProfessionalField | undefined;
  public companySizeItems: DropdownOption[] = [
    { label: '1-50', value: CompanyApi.CompanySize.SMALL },
    { label: '50-250', value: CompanyApi.CompanySize.MEDIUM },
    { label: '250+', value: CompanyApi.CompanySize.LARGE },
  ];
  public address: HereMapsApi.AddressData | undefined;
  public source: CompanyApi.CompanySource | undefined;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly employerApiService: EmployerApiService,
    private readonly employerAuthViewModel: EmployerAuthViewModel,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly utmLocalStorageService: UtmLocalStorageService,
  ) {}

  public buildForm(): void {
    this.step = 0;

    this.employerFormGroup = this.formBuilder.group(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        phoneNumber: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required, strongPasswordValidator]),
        passwordRepeat: new FormControl('', [Validators.required, strongPasswordValidator]),
      },
      {
        validator: confirmPasswordValidatorFunction('password', 'passwordRepeat'),
      },
    );

    this.companyFormGroup = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      //  Used only for validation purposes
      address: new FormControl('', [Validators.required]),
      professionalField: new FormControl('', [Validators.required]),
      companySize: new FormControl('', [Validators.required]),
      termsAgreement: new FormControl('', [Validators.required]),
      privacyAgreement: new FormControl('', [Validators.required]),
    });
  }

  public setAddressValue(address: HereMapsApi.AddressData | undefined): void {
    this.address = address;

    //  Set empty string to break validation in case address is not valid
    this.companyFormGroup.patchValue({ address: address ? 'Valid' : '' });
    this.companyFormGroup.markAsDirty();
    this.companyFormGroup.markAsTouched();
  }

  public setProfessionalFieldValue(professionalField: ProfessionalFieldApi.ProfessionalField | undefined): void {
    this.professionalField = professionalField;

    //  Set empty string to break validation in case address is not valid
    this.companyFormGroup.patchValue({ professionalField: professionalField });
    this.companyFormGroup.get('professionalField')?.markAsDirty();
    this.companyFormGroup.markAsTouched();
  }

  public submit(): void {
    const employer: EmployerApi.CreateEmployer = this.employerFormGroup.value;
    const company: CompanyApi.CreateCompany = this.companyFormGroup.value;

    const registerEmployer: EmployerApi.RegisterEmployer = {
      //  Set address from a variable since the one from the form is used only for validation
      companyAddress: this.address as HereMapsApi.AddressData,
      companyName: company.name,
      companySize: company.companySize,
      professionalField: this.professionalField as ProfessionalFieldApi.ProfessionalField,
      employerEmail: employer.email,
      employerFirstName: employer.firstName,
      employerLastName: employer.lastName,
      employerPassword: employer.password,
      employerPhoneNumber: employer.phoneNumber,
      utm: this.utmLocalStorageService.getUtmData(),
      companySource: this.source ?? CompanyApi.CompanySource.EXTERNAL,
    };

    this.employerApiService
      .register(registerEmployer)
      .pipe(
        take(1),
        tap(employerLoginResponse => {
          this.employerAuthViewModel.storeLoginData(employerLoginResponse);
          this.router.navigate([`/dashboard`]);
          this.alertService.success('Account created successfully.');
          this.utmLocalStorageService.removeUtmData();
        }),
      )
      .subscribe();
  }

  public nextStep(phone: string): void {
    this.employerFormGroup.get('phoneNumber')?.setValue(phone);
    if (!this.employerFormGroup.valid) {
      this.employerFormGroup.markAsDirty();
      this.employerFormGroup.markAsTouched();

      return;
    }

    this.step = 1;
  }
}
