import { Component } from '@angular/core';
import { AlertService } from '@web/web/shared/data-access/alert';

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(public readonly alertService: AlertService) {}
}
