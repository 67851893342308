import { FormGroup } from '@angular/forms';

export function formReset(form: FormGroup): void {
  form.reset();
  form.markAsPristine();
  form.markAsUntouched();
  form.updateValueAndValidity();
  Object.keys(form.controls).forEach(key => {
    form.get(key)?.setErrors(null);
  });
}
