import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JobApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService } from '@web/web/shared/data-access/api';
import { EMPTY, concatMap, map, mergeMap, take, tap, withLatestFrom } from 'rxjs';
import * as RecruitmentProcessActions from './recruitment-process.action';
import { RecruitmentProcessWrite } from './recruitment-process.interface';
import * as RecruitmentProcessSelectors from './recruitment-process.selector';
import * as JobDetailActions from '../../job-detail.action';
import * as JobDetailSelectors from '../../job-detail.selector';

@Injectable()
export class RecruitmentProcessEffect {
  /**
   *
   */
  public readonly updateRecruitmentProcessData$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApiService = inject(JobApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailActions.updateJobDetail),
        tap(() => store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }))),
        withLatestFrom(
          store.select(JobDetailSelectors.selectJobId),
          store.select(JobDetailSelectors.selectActiveTab),
          store.select(RecruitmentProcessSelectors.selectJobDetailRecruitmentProcessWrite),
          store.select(RecruitmentProcessSelectors.selectJobDetailRecruitmentProcessId),
        ),
        mergeMap(([action, jobId, activeTab, editableData, recruitmentProcessId]) => {
          if (!jobId) {
            throw new Error('Job ID is invalid.');
          }

          if (activeTab !== JobApi.JobDetailTab.RECRUITMENT_PROCESS && activeTab !== JobApi.JobDetailTab.ASSESSMENT) {
            return EMPTY;
          }

          return jobApiService
            .updateRecruitmentProcessJobInformation(jobId, this.buildUpdateDto(editableData, recruitmentProcessId))
            .pipe(
              take(1),
              tap(() => alertService.updateSuccess()),
              concatMap(recruitmentProcessWrapper => [
                RecruitmentProcessActions.setJobDetailRecruitmentProcess({ recruitmentProcessWrapper }),
                JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                JobDetailActions.setEditMode({ isEdit: false }),
              ]),
            );
        }),
      );
    },
  );

  /**
   *
   * @param editableData
   * @param recruitmentProcessId
   * @private
   */
  private buildUpdateDto(
    editableData: RecruitmentProcessWrite,
    recruitmentProcessId: string,
  ): JobApi.UpdateRecruitmentProcessJobData {
    return {
      recruitmentProcessId,
      updateAssessments: editableData.assessments.filter(assessment => assessment.id) || [],
      createAssessments: editableData.assessments.filter(assessment => !assessment.id) || [],
      updateSteps: editableData.steps || [],
      deletableSteps: editableData.deletableSteps,
    };
  }
}
