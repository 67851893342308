import { AssessmentApi } from './assessment.namespace';
import { CompanyApi } from './company-api.namespace';
import { CompensationApi } from './compensation-api.namespace';
import { JobApi } from './job-api.namespace';
import { JobTypeApi } from './job-type-api.namespace';
import { NoteApi } from './note-api.namespace';
import { RecruitmentProcessApi } from './recruitment-process-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace ApplicantTrackingSystemApi {
  export interface Applicant {
    id: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    avatarUrl?: string;
    // fields below are only used in the export
    email?: string;
    phone?: string;
    birthDate?: Date;
    address?: string;
    publicProfileLink?: string;
    applications: Application[];
    checked?: boolean;
    picked?: boolean;
    potentialPicked?: boolean;
    currentlyPicked?: boolean;
    currentlyPotentialPicked?: boolean;
    pickBadge?: boolean;
    potentialPickBadge?: boolean;
    age?: number;
    avatarBlur?: boolean;
    newBadge?: boolean;
  }

  export interface Application {
    id: string;
    applicant?: ApplicantATS;
    job: JobATS;
    previousStep?: RecruitmentProcessApi.RecruitmentStep;
    currentStep: RecruitmentProcessApi.RecruitmentStep;
    source: Source;
    checked?: boolean;
    steps?: RecruitmentProcessApi.RecruitmentStep[];
    createdAt?: Date;
    visibleDate?: Date;
    noHireReason?: RecruitmentProcessApi.NoHireReason;
    noHireReasonTranslated?: string;
    noHireText?: string;
    applicantNoFitReason?: RecruitmentProcessApi.ApplicantNoHireReason;
    assessments?: AssessmentApi.AssessmentDetails[];
    notes?: NoteApi.Note[];
    stepHistory?: StepHistory[];
    pickBadge?: boolean;
    potentialPickBadge?: boolean;
    terminateOtherApplications?: boolean;
    interviewMessage?: string;
    isEditable?: boolean;
    isHidden?: boolean;
  }

  export interface ApplicantATS {
    id: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    avatarUrl?: string;
    // fields below are only used in the export
    email?: string;
    phone?: string;
    birthDate?: Date;
    address?: string;
    publicProfileLink?: string;
  }

  export interface JobATS {
    id: string;
    titleVariants?: TextVariantApi.TextVariant[];
    employmentTypes?: JobApi.EmploymentType[];
    company?: CompanyApi.Company;
    compensations?: CompensationApi.Compensation[];
    jobType?: JobTypeApi.JobType;
    city?: string;
    postalCode?: string;
    title?: string;
    jobHeaderImageUrl?: string;
  }

  export enum Source {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
  }

  export interface StepHistory {
    stepLeftDate?: Date;
    stepEnteredDate: Date;
    recruitmentProcessStep: RecruitmentProcessApi.RecruitmentStep;
  }

  export interface FilterData {
    jobs?: JobATS[];
    cities?: string[];
    applicationTypes?: string[];
    recruitmentProcessSteps?: string[];
    timeInStepLessThanDays?: number;
    timeInStepGreaterThanDays?: number;
    order: string;
  }
}
