import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';

Sentry.init({
  dsn: 'https://b1fd743b0568437ea85287606edc9749@o4505192617476096.ingest.sentry.io/4505192659419136',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
  // Tracing
  tracesSampleRate: process.env['NODE_ENV'] === 'production' ? 0.1 : 1,
  allowUrls: ['workerhero.com'],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: process.env['NODE_ENV'] === 'production' ? 0.1 : 1,
  replaysOnErrorSampleRate: process.env['NODE_ENV'] === 'production' ? 0.1 : 1,
  environment: process.env['NODE_ENV'],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
