import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AccountVerificationComponent } from '@web/web/shared/ui/account-verification';
import { ButtonModule } from '@web/web/shared/ui/button';
import { VerifyAccountComponent } from './verify-account.component';

@NgModule({
  imports: [CommonModule, ButtonModule, RouterLink, AccountVerificationComponent],
  declarations: [VerifyAccountComponent],
  exports: [VerifyAccountComponent],
})
export class VerifyAccountModule {}
