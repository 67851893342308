import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LanguageSwitchModule } from '@web/web/shared/ui/language-switch';
import { SidenavItemModule } from '@web/web/shared/ui/sidenav-item';
import { FeatherModule } from 'angular-feather';
import { SidenavComponent } from './sidenav.component';

@NgModule({
  imports: [CommonModule, SidenavItemModule, LanguageSwitchModule, RouterLink, FeatherModule],
  declarations: [SidenavComponent],
  exports: [SidenavComponent],
})
export class SidenavModule {}
