import { createReducer, on } from '@ngrx/store';
import { TextVariantApi, TranslocoApi } from '@web/shared/data-access/model';
import { cloneDeep } from 'lodash';
import * as JobDetailBrandingActions from './branding.action';
import { BrandingPreview, BrandingReadonly, BrandingWrite } from './branding.interface';

export type JobDetailBrandingState = {
  readonly: BrandingReadonly;
  write: BrandingWrite;
  preview: BrandingPreview;
};

const initialJobDetailBrandingState: JobDetailBrandingState = {
  readonly: {
    branding: null,
  },
  write: {
    branding: null,
    hasBeenTouched: false,
    idsOfImagesToDelete: [],
  },
  preview: {
    generatedContent: null,
    shortDescriptionLocale: TranslocoApi.Locale.DE,
    descriptionLocale: TranslocoApi.Locale.DE,
    isLoading: false,
  },
};

export const brandingReducer = createReducer(
  initialJobDetailBrandingState,
  on(
    JobDetailBrandingActions.setJobDetailBranding,
    (state, { branding }): JobDetailBrandingState => ({
      ...state,
      readonly: {
        ...state.readonly,
        branding: { ...branding },
      },
    }),
  ),
  on(
    JobDetailBrandingActions.fillBrandingWriteData,
    (state): JobDetailBrandingState => ({
      ...state,
      write: {
        ...state.write,
        branding: {
          id: state.readonly.branding?.id ?? '',
          images: state.readonly.branding?.images ? [...cloneDeep(state.readonly.branding.images)] : [],
          shortDescription: state.readonly.branding?.shortDescription
            ? [...cloneDeep(state.readonly.branding.shortDescription)]
            : [],
          description: state.readonly.branding?.description ? [...cloneDeep(state.readonly.branding.description)] : [],
          videoLink: state.readonly.branding?.videoLink ?? '',
        },
      },
    }),
  ),
  on(
    JobDetailBrandingActions.writeBrandingData,
    (state, { branding }): JobDetailBrandingState => ({
      ...state,
      write: {
        ...state.write,
        branding: { ...branding, id: state!.write!.branding!.id },
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.deleteImage,
    (state, { id }): JobDetailBrandingState => ({
      ...state,
      write: {
        ...state.write,
        idsOfImagesToDelete: [...state.write.idsOfImagesToDelete, id],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.setGeneratedJobDescriptionPreview,
    (state, { generatedDescription }): JobDetailBrandingState => {
      return {
        ...state,
        preview: {
          ...state.preview,
          generatedContent: generatedDescription,
          isLoading: false,
        },
      };
    },
  ),
  on(
    JobDetailBrandingActions.setGeneratedShortDescriptionPreview,
    (state, { generatedShortDescription }): JobDetailBrandingState => {
      return {
        ...state,
        preview: {
          ...state.preview,
          generatedContent: generatedShortDescription,
          isLoading: false,
        },
      };
    },
  ),
  on(JobDetailBrandingActions.insertGeneratedDescription, (state, { branding }): JobDetailBrandingState => {
    const clonedBranding = cloneDeep(branding);

    clonedBranding.description = [
      ...(clonedBranding.description
        ? cloneDeep(clonedBranding.description).map(description => {
            return description.locale === state.preview.descriptionLocale
              ? ({ ...description, value: state.preview.generatedContent } as TextVariantApi.TextVariant)
              : { ...description };
          })
        : []),
    ];

    return {
      ...state,
      write: {
        ...state.write,
        hasBeenTouched: true,
        branding: { ...clonedBranding, id: state!.write!.branding!.id },
      },
      preview: {
        ...state.preview,
        generatedContent: null,
      },
    };
  }),
  on(JobDetailBrandingActions.insertGeneratedShortDescription, (state, { branding }): JobDetailBrandingState => {
    const clonedBranding = cloneDeep(branding);

    clonedBranding.shortDescription = [
      ...(clonedBranding.shortDescription
        ? cloneDeep(clonedBranding.shortDescription).map(shortDescriptionVariant => {
            return shortDescriptionVariant.locale === state.preview.shortDescriptionLocale
              ? ({ ...shortDescriptionVariant, value: state.preview.generatedContent } as TextVariantApi.TextVariant)
              : { ...shortDescriptionVariant };
          })
        : []),
    ];

    return {
      ...state,
      write: {
        ...state.write,
        hasBeenTouched: true,
        branding: { ...clonedBranding, id: state!.write!.branding!.id },
      },
      preview: {
        ...state.preview,
        generatedContent: null,
      },
    };
  }),
  on(
    JobDetailBrandingActions.resetWriteBrandingData,
    (state): JobDetailBrandingState => ({
      ...state,
      write: {
        ...state.write,
        branding: null,
        hasBeenTouched: false,
        idsOfImagesToDelete: [],
      },
    }),
  ),
  on(
    JobDetailBrandingActions.setDescriptionActiveLanguage,
    (state, { locale }): JobDetailBrandingState => ({
      ...state,
      preview: {
        ...state.preview,
        descriptionLocale: locale,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.setShortDescriptionActiveLanguage,
    (state, { locale }): JobDetailBrandingState => ({
      ...state,
      preview: {
        ...state.preview,
        shortDescriptionLocale: locale,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.clearGeneratedDescriptionPreview,
    (state): JobDetailBrandingState => ({
      ...state,
      preview: {
        ...state.preview,
        generatedContent: null,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.setTranslatedShortJobDescriptionTextVariants,
    (state, { textVariants }): JobDetailBrandingState => ({
      ...state,
      write: {
        ...state.write,
        branding: {
          ...state.write.branding,
          shortDescription: state.write.branding?.shortDescription
            ? state.write.branding.shortDescription.map((textVariantFromStore: TextVariantApi.TextVariant) => ({
                ...textVariantFromStore,
                value: textVariants.find(
                  textVariantFromBackend => textVariantFromBackend.locale === textVariantFromStore.locale,
                )?.value as string,
              }))
            : [],
          description: state.write.branding?.description || [], // Ensure description is always an array
          videoLink: state.write.branding?.videoLink ?? '',
          images: state.write.branding?.images ? state.write.branding?.images : [],
        },
      },
      preview: {
        ...state.preview,
        isLoading: false,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.setTranslatedJobDescriptionTextVariants,
    (state, { textVariants }): JobDetailBrandingState => ({
      ...state,
      write: {
        ...state.write,
        branding: {
          ...state.write.branding,
          description: state.write.branding?.description
            ? state.write.branding.description.map((textVariantFromStore: TextVariantApi.TextVariant) => ({
                ...textVariantFromStore,
                value: textVariants.find(
                  textVariantFromBackend => textVariantFromBackend.locale === textVariantFromStore.locale,
                )?.value as string,
              }))
            : [],
          shortDescription: state.write.branding?.shortDescription || [], // Ensure description is always an array
          videoLink: state.write.branding?.videoLink ?? '',
          images: state.write.branding?.images ? state.write.branding?.images : [],
        },
      },
      preview: {
        ...state.preview,
        isLoading: false,
      },
    }),
  ),
  on(
    JobDetailBrandingActions.setIsAiAssistantLoading,
    (state, { isLoading }): JobDetailBrandingState => ({
      ...state,
      preview: {
        ...state.preview,
        isLoading,
      },
    }),
  ),
);
