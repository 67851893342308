import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavSideGroupModule } from '@web/web/shared/ui/nav-side-group';
import { FeatherModule } from 'angular-feather';
import { NavSideComponent } from './nav-side.component';

@NgModule({
  imports: [CommonModule, FeatherModule, NavSideGroupModule],
  declarations: [NavSideComponent],
  exports: [NavSideComponent],
})
export class NavSideModule {}
