import { createSelector } from '@ngrx/store';
import { selectJobDetailState } from './job-detail.state';

export const selectJobDetailGlobalState = createSelector(selectJobDetailState, state => state.GLOBAL);

export const selectActiveTab = createSelector(selectJobDetailGlobalState, state => state.activeTab);

export const selectJobDetailIsEditMode = createSelector(selectJobDetailGlobalState, state => state.isEdit);

export const selectJobDetailIsLoading = createSelector(selectJobDetailGlobalState, state => state.isLoading);

export const selectJobId = createSelector(selectJobDetailGlobalState, state => state.jobId);

export const selectCompanyId = createSelector(selectJobDetailGlobalState, state => state.companyId);

export const selectIntegrations = createSelector(selectJobDetailGlobalState, state => state.integrations);

export const selectIsReadonly = createSelector(selectJobDetailGlobalState, state => state.isReadonly);

export const selectImages = createSelector(selectJobDetailGlobalState, state => ({
  avatarUrl: state.avatarUrl,
  bannerUrl: state.bannerUrl,
}));
