<div class="wrapper">
  <div class="title-wrapper">
    <h5 *ngIf="navSideGroup.title && isDrawerOpen">{{ navSideGroup.title | transloco }}</h5>
  </div>

  <div class="item-list-wrapper">
    <ng-container *ngFor="let navSideItem of navSideGroup.items">
      <wh-s-nav-side-item
        *ngIf="navSideItem.url && !navSideItem.hidden; else nonRoutableComponent"
        [routerLink]="[navSideItem.url]"
        [routerLinkActive]="['active']"
        [navSideItem]="navSideItem"
        [isDrawerOpen]="isDrawerOpen"
        (click)="actionFired.emit()"
      ></wh-s-nav-side-item>

      <ng-template #nonRoutableComponent>
        <wh-s-nav-side-item
          *ngIf="!navSideItem.hidden"
          [navSideItem]="navSideItem"
          [isDrawerOpen]="isDrawerOpen"
          (click)="actionFired.emit(navSideItem?.actionType)"
        ></wh-s-nav-side-item>
      </ng-template>
    </ng-container>
  </div>

  <hr *ngIf="!isLastInList" />
</div>
