import { AssessmentApi } from './assessment.namespace';

export namespace RecruitmentProcessApi {
  export enum RecruitmentStepType {
    APPLICATION = 'APPLICATION',
    HIRE = 'HIRE',
    NO_HIRE = 'NO_HIRE',
    CUSTOM = 'CUSTOM',
    INTERVIEW = 'INTERVIEW',
    ASSESSMENT = 'ASSESSMENT',
    PICK = 'PICK',
    POTENTIAL_PICK = 'POTENTIAL_PICK',
  }

  // TODO: replace this with translation labels
  export function recruitmentStepTypeToFormattedText(type: RecruitmentStepType): string {
    switch (type) {
      case RecruitmentProcessApi.RecruitmentStepType.APPLICATION:
        return 'Application';
      case RecruitmentProcessApi.RecruitmentStepType.ASSESSMENT:
        return 'Assessment';
      case RecruitmentProcessApi.RecruitmentStepType.CUSTOM:
        return 'Custom';
      case RecruitmentProcessApi.RecruitmentStepType.HIRE:
        return 'Hire';
      case RecruitmentProcessApi.RecruitmentStepType.INTERVIEW:
        return 'Interview';
      case RecruitmentProcessApi.RecruitmentStepType.NO_HIRE:
        return 'No fit';
      case RecruitmentProcessApi.RecruitmentStepType.PICK:
        return 'Pick';
      case RecruitmentProcessApi.RecruitmentStepType.POTENTIAL_PICK:
        return 'Potential pick';
    }
  }

  export function noHireReasonToTranslocoString(reason: NoHireReason): string {
    switch (reason) {
      case NoHireReason.NO_INTERVIEW_RESPONSE:
        return 'shared.ui.no_hire_reason.no_interview_response';
      case NoHireReason.NO_INTEREST:
        return 'shared.ui.no_hire_reason.no_interest';
      case NoHireReason.INSUFFICIENT_LANGUAGE_SKILLS:
        return 'shared.ui.no_hire_reason.insufficient_language_skills';
      case NoHireReason.DISTANCE:
        return 'shared.ui.no_hire_reason.distance';
      case NoHireReason.NO_CONDUCT_CERTIFICATE:
        return 'shared.ui.no_hire_reason.no_conduct_certificate';
      case NoHireReason.NO_DRIVER_LICENSE:
        return 'shared.ui.no_hire_reason.no_driver_license';
      case NoHireReason.LEGAL_REQUIREMENTS:
        return 'shared.ui.no_hire_reason.legal_requirements';
      case NoHireReason.SALARY_EXPECTATIONS:
        return 'shared.ui.no_hire_reason.salary_expectations';
      case NoHireReason.LACK_OF_EXPERIENCE:
        return 'shared.ui.no_hire_reason.lack_of_experience';
      case NoHireReason.NO_TEAM_FIT:
        return 'shared.ui.no_hire_reason.no_team_fit';
      case NoHireReason.INTERVIEW_PERFORMANCE:
        return 'shared.ui.no_hire_reason.interview_performance';
      case NoHireReason.NO_DEMAND:
        return 'shared.ui.no_hire_reason.no_demand';
      case NoHireReason.ASSESSMENT_NOT_PASSED:
        return 'shared.ui.no_hire_reason.assessment_not_passed';
      case NoHireReason.CONTRACT_REJECTED:
        return 'shared.ui.no_hire_reason.contract_rejected';
      case NoHireReason.ALREADY_HIRED:
        return 'shared.ui.no_hire_reason.already_hired';
      case NoHireReason.OVERQUALIFIED:
        return 'shared.ui.no_hire_reason.overqualified';
      case NoHireReason.OTHER:
        return 'shared.ui.no_hire_reason.other';
      case NoHireReason.NO_RESPONSE:
        return 'shared.ui.no_hire_reason.no_response';
      default:
        return '';
    }
  }

  export function defaultRecruitmentStepTypeToTranslocoString(type: RecruitmentStepType): string {
    switch (type) {
      case RecruitmentProcessApi.RecruitmentStepType.APPLICATION:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.application';
      case RecruitmentProcessApi.RecruitmentStepType.HIRE:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.hire';
      case RecruitmentProcessApi.RecruitmentStepType.NO_HIRE:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.no-hire';
      case RecruitmentProcessApi.RecruitmentStepType.PICK:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.pick';
      case RecruitmentProcessApi.RecruitmentStepType.POTENTIAL_PICK:
        return 'shared.namespace.recruitment-process-api.recruitment-step-types.potential-pick';
    }

    return '';
  }

  export interface RecruitmentProcess {
    id: string;
    steps: RecruitmentStep[];
  }

  export interface CreateRecruitmentStep {
    title: string;
    positionIndex: number;
    isHidden: boolean;
    syncActive: boolean;
    externalJobId?: string;
    recruitmentProcessType: RecruitmentStepType;
    autoMoveHere?: boolean;
  }

  export interface RecruitmentStep extends CreateRecruitmentStep {
    id: string;
    createdAt?: Date;
    timeSpentInStep?: string;
    assessment?: AssessmentApi.Assessment;
  }

  export enum NoHireReason {
    NO_INTERVIEW_RESPONSE = 'Candidate did not respond after the interview',
    NO_INTEREST = 'Candidate not interested anymore',
    INSUFFICIENT_LANGUAGE_SKILLS = 'Candidate did not have sufficient language skills',
    DISTANCE = 'Candidate too far away',
    NO_CONDUCT_CERTIFICATE = 'Missing certificate of conduct',
    NO_DRIVER_LICENSE = 'Missing Driver License',
    LEGAL_REQUIREMENTS = 'Candidate did not meet legal requirements',
    SALARY_EXPECTATIONS = 'Candidate did not meet salary expectations',
    LACK_OF_EXPERIENCE = 'Lack of experience',
    NO_TEAM_FIT = 'Candidate is not a fit for the team',
    INTERVIEW_PERFORMANCE = 'Poor Interview Performance',
    NO_DEMAND = 'No demand from the company',
    ASSESSMENT_NOT_PASSED = 'Candidate did not pass the assessment',
    CONTRACT_REJECTED = 'Candidate rejected the contract',
    ALREADY_HIRED = 'Candidate already hired for other position',
    OVERQUALIFIED = 'Candidate is overqualified',
    OTHER = 'Other',
    NO_RESPONSE = 'Candidate did not respond',
  }

  export enum ApplicantNoHireReason {
    LOW_SALARY = 'Low salary',
    DISTANCE = 'Too far away',
    NOT_INTERESTED = 'Not interested',
    NOT_APPEALING_JOB_DESCRIPTION = 'Not appealing job description',
    COMPANY_CULTURE = 'Company culture',
    ALREADY_HAD_INTERVIEW = 'Already had interview',
    WORKING_HOURS_DO_NOT_FIT = 'Working hours do not fit',
    MISSING_BENEFITS = 'Missing benefits',
    MISSING_DEVELOPMENT_OPPORTUNITY = 'Missing development opportunity',
    OTHER = 'Other',
  }
}
