import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JobApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { AssessmentApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { EMPTY, concatMap, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs';
import * as JobDetailActions from '../../job-detail.action';
import * as JobDetailSelectors from '../../job-detail.selector';
import * as JobDetailApplicationActions from '../application/application.action';

@Injectable()
export class ApplicationEffect {
  public readonly fetchAssessment$ = createEffect(
    (actions$ = inject(Actions), store = inject(Store), assessmentApiService = inject(AssessmentApiService)) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.fetchAssessment),
        tap(() => {
          store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }));
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return assessmentApiService.getAssessmentParticipationsByApplicantIdAndJobId(action.applicantId, jobId).pipe(
            take(1),
            concatMap(assessment => [
              JobDetailApplicationActions.setAssessment({ assessment }),
              JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
              JobDetailActions.setEditMode({ isEdit: false }),
            ]),
          );
        }),
      );
    },
  );

  public readonly enable2ndChanceAssessment$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      assessmentApiService = inject(AssessmentApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.enable2ndChanceAssessment),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return assessmentApiService.enable2ndChance(action.assessmentId, action.applicantId).pipe(
            take(1),
            tap(() => alertService.updateSuccess()),
            concatMap(() => [JobDetailApplicationActions.setLoadingState({ isLoading: false })]),
          );
        }),
      );
    },
  );

  public readonly sendRemainderEmail$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      assessmentApiService = inject(AssessmentApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.sendRemainderEmail),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return assessmentApiService.sendAssessmentReminderEmail(action.assessmentId, action.applicantId).pipe(
            take(1),
            tap(() => alertService.updateSuccess()),
            concatMap(() => [JobDetailApplicationActions.setLoadingState({ isLoading: false })]),
          );
        }),
      );
    },
  );

  public readonly setAssessmentStatusFailed$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      assessmentApiService = inject(AssessmentApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.updateAssessmentStatus),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return assessmentApiService.updateAssessmentParticipationStatus(action.participationId, action.status).pipe(
            take(1),
            tap(() => alertService.updateSuccess()),
            switchMap(() =>
              assessmentApiService
                .getAssessmentParticipationsByApplicantIdAndJobId(action.applicantId, jobId)
                .pipe(
                  concatMap(assessment => [
                    JobDetailApplicationActions.setAssessment({ assessment }),
                    JobDetailApplicationActions.setLoadingState({ isLoading: false }),
                  ]),
                ),
            ),
          );
        }),
      );
    },
  );

  public readonly toggleApplicationVisibility$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApplicationApiService = inject(JobApplicationApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.toggleApplicationVisibility),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return jobApplicationApiService
            .hideApplication({
              ...action.application,
              isHidden: !action.application.isHidden,
            })
            .pipe(
              take(1),
              tap(() => alertService.updateSuccess()),
              concatMap(() => [
                JobDetailApplicationActions.setApplicationVisibility({
                  applicationId: action.application.id,
                  isHidden: !action.application.isHidden,
                }),
                JobDetailApplicationActions.setLoadingState({ isLoading: false }),
              ]),
            );
        }),
      );
    },
  );

  public readonly deleteApplication$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApplicationApiService = inject(JobApplicationApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.deleteApplication),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return jobApplicationApiService.delete(action.id).pipe(
            take(1),
            tap(() => alertService.deleteSuccess()),
            concatMap(() => [
              JobDetailApplicationActions.removeFromStore({ id: action.id }),
              JobDetailApplicationActions.setLoadingState({ isLoading: false }),
            ]),
          );
        }),
      );
    },
  );

  public readonly updateApplicationPhase$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApplicationApiService = inject(JobApplicationApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.updateApplicationPhase),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
          store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return jobApplicationApiService.updateApplication(action.application, action.skipCommunication).pipe(
            take(1),
            switchMap(() =>
              jobApplicationApiService.getManyByJobId(jobId).pipe(
                take(1),
                tap(() => alertService.updateSuccess()),
                concatMap(applications => [
                  JobDetailApplicationActions.setJobDetailApplications({ applications }),
                  JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                  JobDetailApplicationActions.setLoadingState({ isLoading: false }),
                ]),
              ),
            ),
          );
        }),
      );
    },
  );

  public readonly abortApplicationPhase$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApplicationApiService = inject(JobApplicationApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailApplicationActions.abortApplication),
        tap(() => {
          store.dispatch(JobDetailApplicationActions.setLoadingState({ isLoading: true }));
          store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }));
        }),
        withLatestFrom(store.select(JobDetailSelectors.selectJobId), store.select(JobDetailSelectors.selectActiveTab)),
        mergeMap(([action, jobId, activeTab]) => {
          if (activeTab !== JobApi.JobDetailTab.APPLICATION || !jobId) {
            return EMPTY;
          }

          return jobApplicationApiService.abort(action.abortApplication).pipe(
            take(1),
            switchMap(() =>
              jobApplicationApiService.getManyByJobId(jobId).pipe(
                take(1),
                tap(() => alertService.updateSuccess()),
                concatMap(applications => [
                  JobDetailApplicationActions.setJobDetailApplications({ applications }),
                  JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                  JobDetailApplicationActions.setLoadingState({ isLoading: false }),
                ]),
              ),
            ),
          );
        }),
      );
    },
  );
}
