import { createAction, props } from '@ngrx/store';
import { CompanyApi, CompensationApi, JobApi, TextVariantApi, TranslocoApi } from '@web/shared/data-access/model';
import { GeneralInfo } from 'web/shared/ui/job-general-info';

enum ActionType {
  SET_IS_LOADING_COMPANY = '[General] Set isLoading on company list',
  SET_GENERAL_DATA = '[General] Load detail job general data',
  FETCH_ALL_COMPANIES = '[General] Fetch all companies',
  SET_COMPANIES = '[General] Set companies',
  SET_SELECTED_COMPANY = '[General] Set selected company',
  SET_GENERAL_INFO = '[General] Set general info',
  SET_EMPLOYMENT_TYPES = '[General] Set employment types',
  SET_COMPENSATIONS = '[General] Set compensations',
  SET_SELECTED_FEATURE = '[General tab] Set selected feature',
  SET_SELECTED_JOB_STATUS = '[General] Set selected job status',
  SET_SELECTED_EXPIRATION_DATE = '[General] Set selected expiration date',
  SET_EXPIRATION_DATE_VALIDITY = '[General] Set expiration date validity',
  SET_TITLE_LOCALE = '[General] Set title active locale',
  SET_EXTERNAL_JOB_ID = '[General] Set external job id',
  SET_IS_AI_ASSISTANT_LOADING = '[General] Set AI Assistant state',
  FILL_WRITE_DATA = '[General] Fill in write data',
  RESET_GENERAL_WRITE_STATE = '[General] Reset general write state',
  TRANSLATE_JOB_TITLE = '[General] Translate job title',
  SET_TITLE_TEXT_VARIANTS = '[General] Set translated job title text variants',
}

export const setIsLoadingCompanyList = createAction(ActionType.SET_IS_LOADING_COMPANY, props<{ isLoading: boolean }>());

export const setJobDetailGeneralData = createAction(
  ActionType.SET_GENERAL_DATA,
  props<{ jobDetailGeneral: JobApi.JobDetailGeneral }>(),
);

export const fetchAllCompanies = createAction(ActionType.FETCH_ALL_COMPANIES);

export const setCompanies = createAction(ActionType.SET_COMPANIES, props<{ companies: CompanyApi.Company[] }>());

export const writeSelectedCompany = createAction(
  ActionType.SET_SELECTED_COMPANY,
  props<{ company: CompanyApi.Company | null }>(),
);

export const writeGeneralInfo = createAction(ActionType.SET_GENERAL_INFO, props<{ generalInfo: GeneralInfo }>());

export const writeEmploymentTypes = createAction(
  ActionType.SET_EMPLOYMENT_TYPES,
  props<{ selectedEmploymentTypes: JobApi.EmploymentType[] }>(),
);

export const writeCompensations = createAction(
  ActionType.SET_COMPENSATIONS,
  props<{ compensationType: CompensationApi.Compensation[] }>(),
);

export const writeSelectedFeature = createAction(
  ActionType.SET_SELECTED_FEATURE,
  props<{ feature: JobApi.AvailableFeature }>(),
);

export const writeSelectedJobStatus = createAction(
  ActionType.SET_SELECTED_JOB_STATUS,
  props<{ jobStatus: JobApi.JobStatus | null }>(),
);

export const writeSelectedExpirationDate = createAction(
  ActionType.SET_SELECTED_EXPIRATION_DATE,
  props<{ expirationDate: string | null }>(),
);

export const checkExpirationDateValidity = createAction(
  ActionType.SET_EXPIRATION_DATE_VALIDITY,
  props<{ isExpirationDateValid: boolean }>(),
);

export const setTitleLocale = createAction(ActionType.SET_TITLE_LOCALE, props<{ locale: TranslocoApi.Locale }>());

export const setExternalJobId = createAction(ActionType.SET_EXTERNAL_JOB_ID, props<{ externalJobId: string }>());

export const setIsAiAssistantLoadingInGeneralTab = createAction(
  ActionType.SET_IS_AI_ASSISTANT_LOADING,
  props<{ isLoading: boolean }>(),
);

export const fillGeneralWriteData = createAction(ActionType.FILL_WRITE_DATA);

export const resetGeneralWriteState = createAction(ActionType.RESET_GENERAL_WRITE_STATE);

export const translateJobTitle = createAction(ActionType.TRANSLATE_JOB_TITLE);

export const setTitleTextVariants = createAction(
  ActionType.SET_TITLE_TEXT_VARIANTS,
  props<{ textVariants: TextVariantApi.CreateTextVariant[] }>(),
);
