import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JobApi, OpenAiApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService, OpenAiApiService } from '@web/web/shared/data-access/api';
import { EMPTY, concatMap, mergeMap, take, tap, withLatestFrom } from 'rxjs';
import * as BrandingActions from './branding.action';
import { BrandingWrite } from './branding.interface';
import * as BrandingSelectors from './branding.selector';
import * as JobDetailActions from '../../job-detail.action';
import * as JobDetailSelectors from '../../job-detail.selector';

@Injectable()
export class BrandingEffect {
  /**
   *
   */
  public readonly updateBrandingData$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApiService = inject(JobApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailActions.updateJobDetail),
        tap(() => store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }))),
        withLatestFrom(
          store.select(JobDetailSelectors.selectJobId),
          store.select(JobDetailSelectors.selectActiveTab),
          store.select(BrandingSelectors.selectJobDetailBrandingWrite),
        ),
        mergeMap(([action, jobId, activeTab, writeData]) => {
          if (activeTab !== JobApi.JobDetailTab.BRANDING || !jobId) {
            return EMPTY;
          }

          return jobApiService.updateBranding(this.buildUpdateDto(jobId, writeData)).pipe(
            take(1),
            tap(() => alertService.updateSuccess()),
            concatMap(branding => [
              BrandingActions.setJobDetailBranding({ branding }),
              JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
              JobDetailActions.setEditMode({ isEdit: false }),
            ]),
          );
        }),
      );
    },
  );

  public readonly generateDescription$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      openAiApiService = inject(OpenAiApiService),
      jobApiService = inject(JobApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(BrandingActions.generateJobDescription),
        withLatestFrom(
          store.select(JobDetailSelectors.selectJobId),
          store.select(JobDetailSelectors.selectActiveTab),
          store.select(BrandingSelectors.selectJobDetailPreviewState),
        ),
        tap(() => store.dispatch(BrandingActions.setIsAiAssistantLoading({ isLoading: true }))),
        mergeMap(([action, jobId, activeTab, previewState]) => {
          if (activeTab !== JobApi.JobDetailTab.BRANDING || !jobId) {
            return EMPTY;
          }

          return jobApiService.getJobDetailGeneral(jobId).pipe(
            mergeMap(details => {
              const generateJobDescription: OpenAiApi.GenerateJobDescription = {
                address: details.address.city + ', ' + details.address.street,
                employmentTypes: details.employmentTypes,
                jobType:
                  details.jobType?.textVariants.find(
                    textVariant =>
                      textVariant.locale ===
                      (action.descriptionType === JobApi.DescriptionType.LONG_DESCRIPTION
                        ? previewState.descriptionLocale
                        : previewState.shortDescriptionLocale),
                  )?.value ?? '',
                jobTitle: details.titleTextVariants.find(variant => variant.value)?.value ?? '',
                locale:
                  action.descriptionType === JobApi.DescriptionType.LONG_DESCRIPTION
                    ? previewState.descriptionLocale
                    : previewState.shortDescriptionLocale,
                companyName: details.company.name,
              };

              if (action.descriptionType === JobApi.DescriptionType.LONG_DESCRIPTION) {
                return openAiApiService.generateJobDescription(generateJobDescription).pipe(
                  take(1),
                  tap(() => alertService.updateSuccess()),
                  concatMap(generatedDescription => {
                    return [BrandingActions.setGeneratedJobDescriptionPreview({ generatedDescription })];
                  }),
                );
              } else {
                return openAiApiService.generateJobDescriptionShort(generateJobDescription).pipe(
                  take(1),
                  tap(() => alertService.updateSuccess()),
                  concatMap(generatedShortDescription => {
                    return [BrandingActions.setGeneratedShortDescriptionPreview({ generatedShortDescription })];
                  }),
                );
              }
            }),
          );
        }),
      );
    },
  );

  public readonly generateTranslations$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      openAiApiService = inject(OpenAiApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(BrandingActions.generateTranslations),
        withLatestFrom(
          store.select(JobDetailSelectors.selectJobId),
          store.select(JobDetailSelectors.selectActiveTab),
          store.select(BrandingSelectors.selectJobDetailPreviewState),
          store.select(BrandingSelectors.selectShortJobDescriptionTextVariants),
          store.select(BrandingSelectors.selectJobDescriptionTextVariants),
        ),
        tap(() => store.dispatch(BrandingActions.setIsAiAssistantLoading({ isLoading: true }))),
        mergeMap(
          ([action, jobId, activeTab, previewState, shortJobDescriptionTextVariants, jobDescriptionTextVariants]) => {
            if (activeTab !== JobApi.JobDetailTab.BRANDING || !jobId) {
              return EMPTY;
            }

            if (action.descriptionType === JobApi.DescriptionType.LONG_DESCRIPTION) {
              const locale = previewState.descriptionLocale;
              let text = '';

              jobDescriptionTextVariants?.forEach(variant => {
                if (variant.locale === locale) {
                  text = variant.value;
                }
              });

              return openAiApiService
                .translateTextVariants({
                  text,
                  locale,
                })
                .pipe(
                  take(1),
                  tap(() => alertService.updateSuccess()),
                  concatMap(textVariants => {
                    return [BrandingActions.setTranslatedJobDescriptionTextVariants({ textVariants })];
                  }),
                );
            } else {
              const locale = previewState.shortDescriptionLocale;
              let text = '';

              shortJobDescriptionTextVariants?.forEach(variant => {
                if (variant.locale === locale) {
                  text = variant.value;
                }
              });

              return openAiApiService
                .translateTextVariants({
                  text,
                  locale,
                })
                .pipe(
                  take(1),
                  tap(() => alertService.updateSuccess()),
                  concatMap(textVariants => {
                    return [BrandingActions.setTranslatedShortJobDescriptionTextVariants({ textVariants })];
                  }),
                );
            }
          },
        ),
      );
    },
  );

  /**
   * Builds DTO out of ngrx-store data
   *
   * @param jobId
   * @param writeData
   * @private
   */
  private buildUpdateDto(jobId: string, writeData: BrandingWrite): JobApi.JobDetailBrandingUpdate {
    //  Add new images
    const newImages: { isHeader: boolean; name: string; file: File }[] = [];
    writeData?.branding?.images.forEach(image => {
      if (!image.id) {
        newImages.push({ isHeader: !!image.isHeader, name: image!.name, file: image!.image as File });
      }
    });

    //  Update images
    const updateImages: { id: string; isHeader: boolean }[] = [];
    writeData?.branding?.images.forEach(image => {
      if (image.id) {
        updateImages.push({ id: image.id, isHeader: !!image.isHeader });
      }
    });

    return {
      id: writeData?.branding?.id || '',
      jobId,
      videoLink: writeData?.branding?.videoLink || '',
      shortDescription: writeData?.branding?.shortDescription || [],
      description: writeData?.branding?.description || [],
      newImages,
      updateImages,
      idOfImagesToDelete: writeData.idsOfImagesToDelete,
    };
  }
}
