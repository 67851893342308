import { createReducer, on } from '@ngrx/store';
import { HereMapsApi, TextVariantApi, TranslocoApi } from '@web/shared/data-access/model';
import { TextVariantLanguage } from 'web/shared/feature/text-variant-input';
import * as JobDetailGeneralActions from './general.action';
import { convertReadonlyDataToWriteData } from './general.function';
import { GeneralPreview, GeneralReadonly, GeneralWrite } from './general.interface';

export type JobDetailGeneralState = {
  readonly: GeneralReadonly;
  write: GeneralWrite;
  preview: GeneralPreview;
};

const initialJobDetailGeneralState: JobDetailGeneralState = {
  readonly: {
    companies: {
      list: [],
      isLoading: false,
    },
    initial: null,
  },
  write: {
    generalInfo: null,
    selectedEmploymentTypes: [],
    compensationType: [],
    //  Publish stuff
    jobStatus: null,
    selectedCompany: null,
    selectedFeature: null,
    selectedExpirationDate: null,
    isExpirationDateValid: false,
    publicationDate: null,
    hasBeenTouched: false,
    externalJobId: null,
  },
  preview: {
    generatedContent: null,
    isLoading: false,
    titleLocale: TranslocoApi.Locale.DE,
  },
};

export const generalReducer = createReducer(
  initialJobDetailGeneralState,
  on(
    JobDetailGeneralActions.setJobDetailGeneralData,
    (state, { jobDetailGeneral }): JobDetailGeneralState => ({
      ...state,
      readonly: {
        ...state.readonly,
        initial: { ...jobDetailGeneral },
      },
    }),
  ),
  on(
    JobDetailGeneralActions.setCompanies,
    (state, { companies }): JobDetailGeneralState => ({
      ...state,
      readonly: {
        ...state.readonly,
        companies: {
          ...state.readonly.companies,
          list: companies,
          isLoading: false,
        },
      },
    }),
  ),
  on(
    JobDetailGeneralActions.setIsLoadingCompanyList,
    (state, { isLoading }): JobDetailGeneralState => ({
      ...state,
      readonly: {
        ...state.readonly,
        companies: {
          ...state.readonly.companies,
          isLoading: isLoading,
        },
      },
    }),
  ),
  on(
    JobDetailGeneralActions.writeGeneralInfo,
    (state, { generalInfo }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        generalInfo: generalInfo,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.writeEmploymentTypes,
    (state, { selectedEmploymentTypes }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        selectedEmploymentTypes: selectedEmploymentTypes,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.writeCompensations,
    (state, { compensationType }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        compensationType: compensationType,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.writeSelectedFeature,
    (state, { feature }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        selectedFeature: feature,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.writeSelectedJobStatus,
    (state, { jobStatus }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        jobStatus: jobStatus,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.writeSelectedExpirationDate,
    (state, { expirationDate }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        selectedExpirationDate: expirationDate,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.checkExpirationDateValidity,
    (state, { isExpirationDateValid }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        isExpirationDateValid: isExpirationDateValid,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.fillGeneralWriteData,
    (state): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        ...convertReadonlyDataToWriteData(state.readonly.initial),
      },
    }),
  ),
  on(
    JobDetailGeneralActions.resetGeneralWriteState,
    (state): JobDetailGeneralState => ({
      ...state,
      write: {
        generalInfo: null,
        selectedEmploymentTypes: [],
        compensationType: [],
        jobStatus: null,
        selectedCompany: null,
        selectedFeature: null,
        selectedExpirationDate: null,
        isExpirationDateValid: false,
        publicationDate: null,
        hasBeenTouched: false,
        externalJobId: null,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.setTitleLocale,
    (state, { locale }): JobDetailGeneralState => ({
      ...state,
      preview: {
        ...state.preview,
        titleLocale: locale,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.setExternalJobId,
    (state, { externalJobId }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        externalJobId,
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailGeneralActions.setTitleTextVariants,
    (state, { textVariants }): JobDetailGeneralState => ({
      ...state,
      write: {
        ...state.write,
        generalInfo: {
          ...state.write.generalInfo,
          jobTitle: textVariants.map(textVariant => ({
            text: textVariant.locale,
            emoji: TranslocoApi.getEmoji(textVariant.locale),
            value: textVariant.value,
          })),
          jobAddress: state.write?.generalInfo?.jobAddress as HereMapsApi.AddressData,
        },
        hasBeenTouched: true,
      },
      preview: {
        ...state.preview,
        isLoading: false,
      },
    }),
  ),
);
