import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoApi } from '@web/shared/data-access/model';
import { EmployerAuthViewModel, EmployerLoginViewModel } from '@web/web/employer/core/auth/data-access';
import { ApplicationModalViewModel, EmployerSidenavService } from '@web/web/employer/shared/data-access';
import { AlertService } from '@web/web/shared/data-access/alert';
import { EmployerApiService } from '@web/web/shared/data-access/api';
import { LanguageSwitchService } from '@web/web/shared/data-access/language';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { take, tap } from 'rxjs';

@Component({
  selector: 'wh-employer-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  constructor(
    public readonly employerAuthViewModel: EmployerAuthViewModel,
    public readonly employerLoginViewModel: EmployerLoginViewModel,
    public readonly sideNavService: EmployerSidenavService,
    public readonly alertService: AlertService,
    public readonly languageSwitchService: LanguageSwitchService,
    public readonly loadingViewModel: LoadingViewModel,
    public readonly applicationModalViewModel: ApplicationModalViewModel,
    private readonly router: Router,
    private readonly employerApiService: EmployerApiService,
  ) {}

  public ngOnInit(): void {
    this.employerLoginViewModel.redirect();
    this.applicationModalViewModel.checkApplicationStatus();
    this.applicationModalViewModel.checkIfVisitedNewVersion();
  }

  public onLogout(): void {
    this.employerApiService
      .logout()
      .pipe(
        take(1),
        tap(() => {
          this.employerAuthViewModel.logout();
        }),
      )
      .subscribe();
  }

  public onHelp(): void {
    // this.helpViewModel.toggleHelpDialog();
    window.open(
      'https://client-support.workerhero.com/portal/en/newticket?departmentId=64787000006340262&layoutId=64787000006351689',
      '_blank',
    );
  }

  public switchLanguage(selectedLanguage: TranslocoApi.Locale): void {
    this.languageSwitchService.setSelectedLanguage(selectedLanguage);
  }

  public profileClick(): void {
    this.router.navigateByUrl('profile');
  }

  public logoClick(): void {
    this.router.navigateByUrl('dashboard');
  }
}
