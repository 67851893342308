import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProfessionalFieldApi } from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalFieldApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) baseUrl: string,
  ) {
    this.url = `${baseUrl}/professional-fields`;
  }

  public getManyFromAdmin(
    pageSize: number,
    currentPage: number,
    searchTerm: string,
  ): Observable<PaginateResult<ProfessionalFieldApi.ProfessionalField>> {
    return this.httpClient.get<PaginateResult<ProfessionalFieldApi.ProfessionalField>>(`${this.url}/admin`, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize).set('searchTerm', searchTerm),
    });
  }

  public getMany(
    pageSize: number,
    currentPage: number,
  ): Observable<PaginateResult<ProfessionalFieldApi.ProfessionalField>> {
    return this.httpClient.get<PaginateResult<ProfessionalFieldApi.ProfessionalField>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public getAll(): Observable<ProfessionalFieldApi.ProfessionalField[]> {
    return this.httpClient.get<ProfessionalFieldApi.ProfessionalField[]>(`${this.url}/all`);
  }

  public getAllFromAdmin(): Observable<ProfessionalFieldApi.ProfessionalField[]> {
    return this.httpClient.get<ProfessionalFieldApi.ProfessionalField[]>(`${this.url}/all/admin`);
  }

  public findByQuery(searchTerm: string): Observable<ProfessionalFieldApi.ProfessionalField[]> {
    return this.httpClient.get<ProfessionalFieldApi.ProfessionalField[]>(`${this.url}/search`, {
      params: { searchTerm },
    });
  }

  public create(
    professionalField: ProfessionalFieldApi.CreateProfessionalField,
  ): Observable<ProfessionalFieldApi.ProfessionalField> {
    return this.httpClient.post<ProfessionalFieldApi.ProfessionalField>(`${this.url}`, professionalField);
  }

  public update(
    updateProfessionalField: ProfessionalFieldApi.UpdateProfessionalField,
  ): Observable<ProfessionalFieldApi.ProfessionalField> {
    return this.httpClient.put<ProfessionalFieldApi.ProfessionalField>(`${this.url}`, updateProfessionalField);
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }
}
