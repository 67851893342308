import { createReducer, on } from '@ngrx/store';
import { IntegrationApi, JobApi } from '@web/shared/data-access/model';
import * as JobDetailActions from './job-detail.action';

export const JOB_DETAIL_FEATURE_KEY = 'JOB_DETAIL_FEATURE_KEY';

export type JobDetailState = {
  activeTab: JobApi.JobDetailTab;
  jobId: string | null;
  companyId: string | null;
  isLoading: boolean;
  isEdit: boolean;
  isReadonly: boolean;
  avatarUrlFallBack: string;
  bannerUrlFallBack: string;
  avatarUrl: string;
  bannerUrl: string;
  integrations: IntegrationApi.KomboIntegration[];
};

const initialJobDetailState: JobDetailState = {
  jobId: null,
  companyId: null,
  activeTab: JobApi.JobDetailTab.GENERAL,
  isEdit: false,
  isLoading: false,
  isReadonly: false,
  avatarUrlFallBack: '/assets/image/company-logo-fallback.jpg',
  bannerUrlFallBack: '/assets/image/fallback-banner.svg',
  avatarUrl: '/assets/image/company-logo-fallback.jpg',
  bannerUrl: '/assets/image/fallback-banner.svg',
  integrations: [],
};

export const jobDetailReducer = createReducer(
  initialJobDetailState,
  on(
    JobDetailActions.setJobId,
    (state, { jobId }): JobDetailState => ({
      ...state,
      jobId: jobId,
    }),
  ),
  on(
    JobDetailActions.setCompanyId,
    (state, { companyId }): JobDetailState => ({
      ...state,
      companyId: companyId,
    }),
  ),
  on(
    JobDetailActions.setIntegrations,
    (state, { integrations }): JobDetailState => ({
      ...state,
      integrations: integrations,
    }),
  ),
  on(
    JobDetailActions.setActiveTabAction,
    (state, { activeTab }): JobDetailState => ({
      ...state,
      activeTab: activeTab,
    }),
  ),
  on(
    JobDetailActions.removeJobDetail,
    (state): JobDetailState => ({
      ...state,
      activeTab: JobApi.JobDetailTab.GENERAL,
      jobId: null,
      avatarUrl: state.avatarUrlFallBack,
      bannerUrl: state.bannerUrlFallBack,
    }),
  ),
  on(
    JobDetailActions.setIsJobDetailLoading,
    (state, { isLoading }): JobDetailState => ({
      ...state,
      isLoading: isLoading,
    }),
  ),
  on(
    JobDetailActions.toggleEditMode,
    (state): JobDetailState => ({
      ...state,
      isEdit: !state.isEdit,
    }),
  ),
  on(
    JobDetailActions.toggleReadonlyMode,
    (state): JobDetailState => ({
      ...state,
      isReadonly: !state.isReadonly,
    }),
  ),
  on(
    JobDetailActions.setReadonlyMode,
    (state, { isReadonly }): JobDetailState => ({
      ...state,
      isReadonly: isReadonly,
    }),
  ),
  on(
    JobDetailActions.setEditMode,
    (state, { isEdit }): JobDetailState => ({
      ...state,
      isEdit: isEdit,
    }),
  ),
  on(
    JobDetailActions.setCompanyImages,
    (state, { avatarUrl, bannerUrl }): JobDetailState => ({
      ...state,
      avatarUrl: state.avatarUrl === state.avatarUrlFallBack ? avatarUrl : state.avatarUrl,
      bannerUrl: state.bannerUrl === state.bannerUrlFallBack ? bannerUrl : state.bannerUrl,
    }),
  ),
);
