import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { NavSideItemModule } from '@web/web/shared/ui/nav-side-item';
import { NavSideGroupComponent } from './nav-side-group.component';

@NgModule({
  imports: [CommonModule, NavSideItemModule, RouterLink, RouterLinkActive, WebCoreI18nModule],
  declarations: [NavSideGroupComponent],
  exports: [NavSideGroupComponent],
})
export class NavSideGroupModule {}
