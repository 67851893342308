/**
 * Removes item at specified index and returns new array
 *
 * @param array
 * @param index
 */
export function removeItemAtIndex<T>(array: T[], index: number): T[] {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of bounds');
  }

  return [...array.slice(0, index), ...array.slice(index + 1)];
}
