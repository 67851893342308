// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslocoApi } from '@web/shared/data-access/model';
//  TODO: Fix imports
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TextVariantLanguage } from 'web/shared/feature/text-variant-input';

export function buildVariants(languages: string[], initialValues?: TextVariantLanguage[]): TextVariantLanguage[] {
  if (initialValues?.length) {
    return languages
      .map(
        locale =>
          initialValues.find(i => i.text === locale) ?? {
            text: locale.toUpperCase(),
            value: '',
            emoji: TranslocoApi.getEmoji(<TranslocoApi.Locale>locale),
          },
      )
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  return languages
    .map(locale => ({
      text: locale.toUpperCase(),
      value: '',
      emoji: TranslocoApi.getEmoji(<TranslocoApi.Locale>locale),
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
}
