import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LanguageSwitchModule } from '@web/web/shared/ui/language-switch';
import { NotificationCountComponent } from '@web/web/shared/ui/notification-count';
import { FeatherModule } from 'angular-feather';
import { SidenavItemComponent } from './sidenav-item.component';

@NgModule({
  imports: [
    CommonModule,
    FeatherModule,
    NotificationCountComponent,
    RouterLink,
    RouterLinkActive,
    LanguageSwitchModule,
  ],
  declarations: [SidenavItemComponent],
  exports: [SidenavItemComponent],
})
export class SidenavItemModule {}
