import { Validators } from '@angular/forms';

/**
 * Password Requirements:
 *    At least 8 characters long
 *    At least one number
 *    At least one uppercase
 */
export const STRONG_PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/;

export const strongPasswordValidator = Validators.pattern(STRONG_PASSWORD_REGEX);
