import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JobApi, TranslocoApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { CompanyApiService, JobApiService, OpenAiApiService } from '@web/web/shared/data-access/api';
import { EMPTY, concatMap, map, mergeMap, take, tap, withLatestFrom } from 'rxjs';
import { TextVariantLanguage } from 'web/shared/feature/text-variant-input';
import * as GeneralActions from './general.action';
import { GeneralWrite } from './general.interface';
import * as GeneralSelectors from './general.selector';
import * as JobDetailActions from '../../job-detail.action';
import * as JobDetailSelectors from '../../job-detail.selector';

@Injectable()
export class GeneralEffect {
  /**
   * Fetches all companies
   *
   */
  public readonly loadCompanies$ = createEffect(
    (actions$ = inject(Actions), store = inject(Store), companyApiService = inject(CompanyApiService)) => {
      return actions$.pipe(
        ofType(GeneralActions.fetchAllCompanies),
        mergeMap(() =>
          companyApiService.getAll().pipe(
            take(1),
            map(companies => GeneralActions.setCompanies({ companies })),
          ),
        ),
      );
    },
  );

  /**
   *
   */
  public readonly generateTranslations$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      openAiApiService = inject(OpenAiApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(GeneralActions.translateJobTitle),
        withLatestFrom(
          store.select(JobDetailSelectors.selectJobId),
          store.select(JobDetailSelectors.selectActiveTab),
          store.select(GeneralSelectors.selectJobTitle),
          store.select(GeneralSelectors.selectPreviewStateGeneral),
        ),
        tap(() => store.dispatch(GeneralActions.setIsAiAssistantLoadingInGeneralTab({ isLoading: true }))),
        mergeMap(([action, jobId, activeTab, jobTitle, previewState]) => {
          if (!jobId) {
            throw new Error('Job ID is invalid.');
          }

          if (activeTab !== JobApi.JobDetailTab.GENERAL) {
            return EMPTY;
          }

          const locale = previewState.titleLocale;
          let text = '';

          jobTitle?.forEach(variant => {
            if (variant.text === locale) {
              text = variant.value;
            }
          });

          return openAiApiService
            .translateTextVariants({
              text,
              locale,
            })
            .pipe(
              take(1),
              tap(() => alertService.updateSuccess()),
              concatMap(textVariants => {
                return [GeneralActions.setTitleTextVariants({ textVariants })];
              }),
            );
        }),
      );
    },
  );

  /**
   * Updates the general tab data
   *
   */
  public readonly attemptToUpdateGeneralData$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApiService = inject(JobApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailActions.updateJobDetail),
        tap(() => store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }))),
        withLatestFrom(
          store.select(JobDetailSelectors.selectJobId),
          store.select(JobDetailSelectors.selectActiveTab),
          store.select(GeneralSelectors.selectJobDetailGeneralEditableV2),
        ),
        mergeMap(([action, jobId, activetab, editableData]) => {
          if (!jobId) {
            throw new Error('Job ID is invalid.');
          }

          if (activetab !== JobApi.JobDetailTab.GENERAL) {
            return EMPTY;
          }

          return jobApiService.updateGeneralJobInformation(this.buildUpdateDto(jobId, editableData)).pipe(
            take(1),
            tap(() => alertService.updateSuccess()),
            concatMap(jobDetailGeneral => [
              GeneralActions.setJobDetailGeneralData({ jobDetailGeneral }),
              JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
              JobDetailActions.setEditMode({ isEdit: false }),
            ]),
          );
        }),
      );
    },
  );

  /**
   * Builds DTO out of ngrx-store data
   *
   * @param jobId
   * @param storeData
   * @private
   */
  private buildUpdateDto(jobId: string, storeData: GeneralWrite): JobApi.UpdateGeneralJobData {
    const {
      generalInfo,
      selectedEmploymentTypes,
      compensationType,
      selectedCompany,
      selectedExpirationDate,
      selectedFeature,
      jobStatus,
      externalJobId,
    } = storeData;

    return {
      id: jobId,
      availableFeature: selectedFeature!,
      expirationDate: selectedExpirationDate!,
      externalJobId: externalJobId ?? '',
      companyId: selectedCompany!.id!,
      jobStatus: jobStatus!,
      address: generalInfo!.jobAddress!,
      titleTextVariants: generalInfo!.jobTitle
        .map((title: TextVariantLanguage) => ({
          value: title.value,
          locale: <TranslocoApi.Locale>title.text,
        }))
        .filter(ttv => ttv.value),
      jobTypeId: String(generalInfo!.jobType!.id),
      employmentTypes: selectedEmploymentTypes,
      compensations: compensationType.map(c => ({
        compensationType: c.compensationType,
        id: c.id,
        amountFrom: Number(c.amountFrom),
        amountTo: Number(c.amountTo),
        bonusType: c.bonusType,
        currency: c.currency,
        interval: c.interval,
        bonusInterval: c.bonusInterval,
      })),
    };
  }
}
