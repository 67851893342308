import { animate, style, transition, trigger } from '@angular/animations';

export const enterLeave = trigger('enterLeave', [
  transition(':enter', [style({ opacity: 0 }), animate('0.1s ease-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('0.1s ease-in', style({ opacity: 0 }))]),
]);

export const enter = trigger('enter', [
  transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-in-out', style({ opacity: 1 }))]),
]);

//  TODO: parametrize animation
export const enterLeaveSlow = trigger('enterLeaveSlow', [
  transition(':enter', [style({ opacity: 0 }), animate('0.4s ease', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('0.4s ease', style({ opacity: 0 }))]),
]);
