import { createReducer, on } from '@ngrx/store';
import { objectEquals, removeItemAtIndex } from '@web/web/shared/util/function';
import { cloneDeep } from 'lodash';
import * as JobDetailRecruitmentProcessActions from './recruitment-process.action';
import {
  convertStepsToInfoCard,
  convertToEditableAssessment,
  getRecruitmentTypes,
  insertNewRecruitmentProcessStep,
  normalizePositionIndexes,
} from './recruitment-process.function';
import { RecruitmentProcessReadonly, RecruitmentProcessWrite } from './recruitment-process.interface';

export type JobDetailRecruitmentProcessState = {
  readonly: RecruitmentProcessReadonly;
  write: RecruitmentProcessWrite;
};

const initialJobDetailRecruitmentProcessState: JobDetailRecruitmentProcessState = {
  readonly: {
    assessmentDetails: [],
    stepsAsInfoCards: [],
    assessments: [],
    recruitmentProcess: null,
    autoMoveStep: null,
  },
  write: {
    steps: [],
    deletableSteps: [],
    assessments: [],
    recruitmentTypeOptions: [],
    hasBeenTouched: false,
  },
};

export const recruitmentProcessReducer = createReducer(
  initialJobDetailRecruitmentProcessState,
  on(
    JobDetailRecruitmentProcessActions.setJobDetailRecruitmentProcess,
    (state, { recruitmentProcessWrapper }): JobDetailRecruitmentProcessState => ({
      ...state,
      readonly: {
        ...state.readonly,
        recruitmentProcess: recruitmentProcessWrapper.recruitmentProcess,
        assessmentDetails: recruitmentProcessWrapper.assessments,
        assessments: convertToEditableAssessment(recruitmentProcessWrapper.assessments),
        stepsAsInfoCards: convertStepsToInfoCard(recruitmentProcessWrapper.recruitmentProcess.steps),
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.fillInRecruitmentProcessWriteData,
    (state): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        steps: state.readonly.recruitmentProcess?.steps ? [...state.readonly.recruitmentProcess.steps] : [],
        assessments: [...cloneDeep(state.readonly.assessments || [])],
        recruitmentTypeOptions: getRecruitmentTypes(),
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.writeAssessmentQuestion,
    (state, { assessmentId, question }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  questions: [...assessment.questions, { ...question }],
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.updateAssessmentQuestion,
    (state, { assessmentId, questionToUpdate, index }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                const updatedQuestion = { ...assessment.questions[index], ...questionToUpdate };
                const questions = removeItemAtIndex(assessment.questions, index);

                return {
                  ...assessment,
                  questions: [...questions, { ...updatedQuestion }],
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.deleteAssessmentQuestion,
    (state, { assessmentId, questionToDelete }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  questions: [...assessment.questions.filter(question => !objectEquals(question, questionToDelete))],
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.toggleAutomaticAssessment,
    (state, { assessmentId }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  hasPassScore: !assessment.hasPassScore,
                  score: {
                    score: 0,
                    failCaseStep: undefined,
                    passCaseStep: undefined,
                  },
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.writeAfterPassAssessmentStep,
    (state, { assessmentId, afterPassStep }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  score: {
                    ...assessment.score,
                    passCaseStep: afterPassStep ? { ...afterPassStep } : undefined,
                    score: assessment.score?.score ?? 0,
                  },
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.writeAfterFailAssessmentStep,
    (state, { assessmentId, afterFailStep }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  score: {
                    ...assessment.score,
                    failCaseStep: afterFailStep ? { ...afterFailStep } : undefined,
                    score: assessment.score?.score ?? 0,
                  },
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.writePassScore,
    (state, { assessmentId, passScore }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        assessments: [
          ...cloneDeep(
            state.write.assessments.map(assessment => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  score: {
                    ...assessment.score,
                    score: passScore,
                  },
                };
              }

              return {
                ...assessment,
              };
            }) || [],
          ),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.createNewRecruitmentProcessStep,
    (state, { newStep }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        steps: insertNewRecruitmentProcessStep(state.write.steps, newStep),
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.writeAutoMoveStep,
    (state, { index }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        steps: [
          ...state.write.steps
            .map(step => ({ ...step, autoMoveHere: false }))
            .map((step, indexStep) => {
              if (indexStep === index) {
                return {
                  ...step,
                  autoMoveHere: true,
                };
              }

              return {
                ...step,
              };
            }),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.updateRecruitmentProcessStep,
    (state, { updatedStep }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        steps: [
          ...state.write.steps.map(step => {
            if (step.positionIndex === updatedStep.positionIndex) {
              return {
                ...step,
                ...updatedStep,
                id: step.id,
              };
            }

            return { ...step };
          }),
        ],
        hasBeenTouched: true,
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.deleteRecruitmentProcessStep,
    (state, { index }): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        ...state.write,
        deletableSteps: state.write.steps[index].id
          ? //  Add only items which are present in the DB, the ones that are added on frontend only can simply be deleted
            [...state.write.deletableSteps, state.write.steps[index].id]
          : [...state.write.deletableSteps],
        hasBeenTouched: true,
        assessments: [
          ...state.write.assessments.map(assessment => {
            const updatedScore = {
              ...assessment.score,
              score: assessment.score?.score ?? 0,
            };

            const recruitmentStepToBeRemoved = state.write.steps[index];

            if (assessment?.score?.passCaseStep?.id === recruitmentStepToBeRemoved.id) {
              updatedScore.passCaseStep = undefined;
            }

            if (assessment?.score?.failCaseStep?.id === recruitmentStepToBeRemoved.id) {
              updatedScore.failCaseStep = undefined;
            }

            return { ...assessment, score: { ...updatedScore } };
          }),
        ],
        steps: normalizePositionIndexes(removeItemAtIndex([...state.write.steps], index)),
      },
    }),
  ),
  on(
    JobDetailRecruitmentProcessActions.resetRecruitmentProcessState,
    (state): JobDetailRecruitmentProcessState => ({
      ...state,
      write: {
        steps: [],
        deletableSteps: [],
        assessments: [],
        recruitmentTypeOptions: [],
        hasBeenTouched: false,
      },
    }),
  ),
);
