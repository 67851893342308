import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthApi } from '@web/shared/data-access/model';
import { LoginViewModel } from '@web/web/core/auth/data-access';
import { AlertService } from '@web/web/shared/data-access/alert';
import { EmployerApiService } from '@web/web/shared/data-access/api';
import { tap } from 'rxjs';
import { EmployerAuthViewModel } from './employer-auth.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class EmployerLoginViewModel extends LoginViewModel {
  protected defaultRedirectUrl = '/dashboard';

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    private readonly employerApiService: EmployerApiService,
    private readonly employerAuthViewModel: EmployerAuthViewModel,
    private readonly alertService: AlertService,
  ) {
    super(formBuilder, router);
  }

  public submit(): void {
    const loginData = this.getLoginData();

    if (!loginData) {
      return;
    }

    this.employerApiService
      .login({ ...loginData, client: AuthApi.Client.EMPLOYER_APP })
      .pipe(
        tap(loginResponseDto => {
          this.employerAuthViewModel.storeLoginData(loginResponseDto);
          this.redirect();
          this.alertService.success(`You are successfully logged in. Welcome ${loginResponseDto.firstName}`);
        }),
      )
      .subscribe();
  }
}
