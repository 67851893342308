import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApplicantTrackingSystemApi,
  AssessmentApi,
  JobApplicationApi,
  RecruitmentProcessApi,
} from '@web/shared/data-access/model';
import { PaginateResult } from '@web/shared/util/paginate';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';
import FilterData = ApplicantTrackingSystemApi.FilterData;

@Injectable({
  providedIn: 'root',
})
export class JobApplicationApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/job-application`;
  }

  public getApplicationsByApplicant(): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/applicant`);
  }

  public getApplicationById(id: string): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application>(`${this.url}/applicant/${id}`);
  }

  public getApplicantsPendingAssessments(): Observable<AssessmentApi.AssessmentDetails[]> {
    return this.httpClient.get<AssessmentApi.AssessmentDetails[]>(`${this.url}/applicant/assessments-pending`);
  }

  public getApplicationsByApplicantId(applicantId: string): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/applicants/${applicantId}`);
  }

  public getMany(
    pageSize: number,
    currentPage: number,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Application>> {
    return this.httpClient.get<PaginateResult<ApplicantTrackingSystemApi.Application>>(this.url, {
      params: new HttpParams().set('currentPage', currentPage).set('pageSize', pageSize),
    });
  }

  public applyForJobInternally(jobId: string): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.post<ApplicantTrackingSystemApi.Application>(`${this.url}/job/${jobId}/apply`, {});
  }

  public create(jobApplication: JobApplicationApi.JobApplication): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.post<ApplicantTrackingSystemApi.Application>(`${this.url}`, jobApplication);
  }

  public createForAdmin(
    jobApplication: JobApplicationApi.JobApplication,
  ): Observable<ApplicantTrackingSystemApi.Application> {
    return this.httpClient.post<ApplicantTrackingSystemApi.Application>(`${this.url}/admin`, jobApplication);
  }

  public updateApplications(applications: ApplicantTrackingSystemApi.Application[]): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/all`, applications);
  }

  public updateApplication(
    application: ApplicantTrackingSystemApi.Application,
    skipCommunication = false,
  ): Observable<void> {
    return this.httpClient.put<void>(`${this.url}?skipCommunication=${skipCommunication}`, application);
  }

  public getAllRecruitmentSteps(): Observable<RecruitmentProcessApi.RecruitmentStep[]> {
    return this.httpClient.get<RecruitmentProcessApi.RecruitmentStep[]>(`${this.url}/recruitmentSteps`);
  }

  public exportApplications(data: JSON): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/export-log`, { data });
  }

  public abort(abortApplication: JobApplicationApi.AbortApplication): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/abort`, abortApplication);
  }

  public getSearchResults(
    pageSize: number,
    page: number,
    searchTerm: string,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Application>> {
    return this.httpClient.get<PaginateResult<ApplicantTrackingSystemApi.Application>>(`${this.url}/search-many`, {
      params: new HttpParams().set('currentPage', page).set('pageSize', pageSize).set('searchTerm', searchTerm),
    });
  }

  public getFilters(): Observable<ApplicantTrackingSystemApi.FilterData> {
    return this.httpClient.get<ApplicantTrackingSystemApi.FilterData>(`${this.url}/filters`);
  }

  // use this instead getATS() method in viewmodel
  public getFilteredApplicants(
    pageSize: number,
    page: number,
    searchTerm: string,
    filterData: FilterData,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Applicant>> {
    return this.httpClient.post<PaginateResult<ApplicantTrackingSystemApi.Applicant>>(
      `${this.url}/filtered-applicants`,
      {
        currentPage: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
        filterData: filterData,
      },
    );
  }

  public getATS(
    pageSize: number,
    page: number,
    searchTerm: string,
  ): Observable<PaginateResult<ApplicantTrackingSystemApi.Applicant>> {
    return this.httpClient.get<PaginateResult<ApplicantTrackingSystemApi.Applicant>>(`${this.url}/applicants`, {
      params: new HttpParams().set('currentPage', page).set('pageSize', pageSize).set('searchTerm', searchTerm),
    });
  }

  public delete(jobApplicationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${jobApplicationId}`);
  }

  public checkApplicationStatus(): Observable<JobApplicationApi.ApplicationsStatus> {
    return this.httpClient.get<JobApplicationApi.ApplicationsStatus>(`${this.url}/status`);
  }

  public createChangePhaseRequest(jobApplicationId: string, message: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${jobApplicationId}/change-phase-request`, { message });
  }

  public hideApplication(jobAppDetails: ApplicantTrackingSystemApi.Application | undefined): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/hide`, jobAppDetails);
  }

  public getManyByJobId(jobId: string): Observable<ApplicantTrackingSystemApi.Application[]> {
    return this.httpClient.get<ApplicantTrackingSystemApi.Application[]>(`${this.url}/job/${jobId}`);
  }
}
