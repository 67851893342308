import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthApi, TranslocoApi } from '@web/shared/data-access/model';
import { LanguageItem } from '@web/web/shared/ui/language-switch-new';

@Component({
  selector: 'wh-s-panel-top',
  templateUrl: './panel-top.component.html',
  styleUrls: ['./panel-top.component.scss'],
})
export class PanelTopComponent implements OnChanges {
  @Input()
  public hasAvatar = true;

  @Input()
  public avatarSrc: string;

  @Input()
  public username: string;

  @Input()
  public languages: LanguageItem[] = [];

  @Input()
  public isProfileClickAllowed = true;

  @Input()
  public role: AuthApi.AUTH_ROLE | null = null;

  @Output()
  public languageClicked = new EventEmitter<TranslocoApi.Locale>();

  @Output()
  public toggleDrawer = new EventEmitter<void>();

  @Output()
  public profileClick = new EventEmitter<void>();

  @Output()
  public logoClick = new EventEmitter<void>();

  public sanitizedUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnChanges(): void {
    if (this.avatarSrc) this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.avatarSrc);
  }
}
