import { createSelector } from '@ngrx/store';
import { convertStepsToInfoCard } from './recruitment-process.function';
import { selectJobDetailState } from '../../job-detail.state';

export const selectJobDetailRecruitmentProcessFeature = createSelector(
  selectJobDetailState,
  state => state.RECRUITMENT_PROCESS,
);

export const selectJobDetailRecruitmentProcessWrite = createSelector(
  selectJobDetailRecruitmentProcessFeature,
  state => state.write,
);

export const selectJobDetailRecruitmentProcessId = createSelector(selectJobDetailRecruitmentProcessFeature, state =>
  String(state.readonly.recruitmentProcess?.id),
);

export const selectJobDetailRecruitmentProcessReadonlyState = createSelector(
  selectJobDetailRecruitmentProcessFeature,
  state => state.readonly,
);

export const selectRecruitmentProcessStepsAsInfoCards = createSelector(
  selectJobDetailRecruitmentProcessFeature,
  state => convertStepsToInfoCard(state.write.steps),
);

export const selectRecruitmentProcessStepsAsWithoutReadonlySteps = createSelector(
  selectJobDetailRecruitmentProcessFeature,
  state => [
    ...state.write.steps.filter(
      step =>
        step.title.toLowerCase() !== 'hire' &&
        step.title.toLowerCase() !== 'no fit' &&
        step.title.toLowerCase() !== 'application',
    ),
  ],
);

export const selectRecruitmentProcessSteps = createSelector(
  selectJobDetailRecruitmentProcessFeature,
  state => state.write.steps,
);

export const selectRecruitmentProcessFormTouched = createSelector(
  selectJobDetailRecruitmentProcessFeature,
  state => state.write.hasBeenTouched,
);
