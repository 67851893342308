import { AuthApi } from './auth-api.namespace';
import { CompanyApi } from './company-api.namespace';
import { HereMapsApi } from './here-maps.namespace';
import { ProfessionalFieldApi } from './professional-fields-api.namespace';
import { TranslocoApi } from './transloco-api.namespace';
import { UtmApi } from './utm.namespace';

export namespace EmployerApi {
  export interface Employer {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    role: Role;
    verifiedAt?: Date;
    company?: CompanyApi.Company;
  }

  export interface EmployerProfile {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address: HereMapsApi.AddressData;
    communicationLanguage: TranslocoApi.Locale;
  }

  export interface EmployerLoginResponseDto extends AuthApi.BaseLoginResponseDto {
    role: Role;
    companyId: string;
    companyName: string;
    //  TODO: Move to base interface once all apps have avatar feat
    avatarUrl?: string;
  }

  export enum Role {
    EMPLOYER = 'Employer',
    OWNER = 'Owner',
  }

  export interface CreateEmployer {
    id?: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    email: string;
    role: Role;
    companyId?: string;
  }

  export interface RegisterEmployer {
    employerFirstName: string;
    employerLastName: string;
    employerEmail: string;
    employerPhoneNumber: string;
    employerPassword: string;
    companyName: string;
    companyAddress: HereMapsApi.AddressData;
    companySize: CompanyApi.CompanySize;
    professionalField: ProfessionalFieldApi.ProfessionalField;
    utm?: UtmApi.UtmData;
    companySource?: CompanyApi.CompanySource;
  }

  export interface UpdateEmployer {
    id: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    role?: Role;
    password?: string;
  }
}
