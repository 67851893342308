<div class="nav-wrapper" [ngClass]="{ 'mobile-view': isMobileView }">
  <nav
    [ngClass]="{ 'nav-open': isDrawerOpen, 'nav-closed': !isDrawerOpen }"
    [ngStyle]="{ width: isMobileView ? currentScreenWidth + 'px' : null }"
  >
    <wh-s-nav-side-group
      *ngFor="let sideNavGroup of sideNavItems; let i = index"
      [navSideGroup]="sideNavGroup"
      [isLastInList]="i === sideNavItems.length - 1"
      [isDrawerOpen]="isDrawerOpen"
      (actionFired)="actionFired($event)"
    ></wh-s-nav-side-group>
  </nav>

  <div
    class="content-wrapper"
    [ngClass]="{
      'content-wrapper-shrink': isDrawerOpen,
      'content-wrapper-grow': !isDrawerOpen,
      'responsive-small': isDrawerOpen,
    }"
  >
    <ng-content select="[slot=content]"></ng-content>
  </div>
</div>
