import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OpenAiApi, TextVariantApi } from '@web/shared/data-access/model';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class OpenAiApiService {
  private readonly url: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) baseUrl: string,
  ) {
    this.url = `${baseUrl}/open-ai`;
  }

  public fixGrammarAndImproveText(text: string): Observable<OpenAiApi.ImprovedText> {
    return this.httpClient.post<OpenAiApi.ImprovedText>(`${this.url}/improve-text`, { text });
  }

  public translateTextVariants(
    translateDto: OpenAiApi.TranslateTextVariant,
  ): Observable<TextVariantApi.CreateTextVariant[]> {
    return this.httpClient.post<TextVariantApi.CreateTextVariant[]>(`${this.url}/translate`, translateDto);
  }

  public generateJobDescription(generateJobDescriptionDto: OpenAiApi.GenerateJobDescription): Observable<string> {
    return this.httpClient.post(`${this.url}/job-description`, generateJobDescriptionDto, { responseType: 'text' });
  }

  public generateJobDescriptionShort(generateJobDescriptionDto: OpenAiApi.GenerateJobDescription): Observable<string> {
    return this.httpClient.post(`${this.url}/job-description-short`, generateJobDescriptionDto, {
      responseType: 'text',
    });
  }

  public generateCompanyDescription(
    generateCompanyDescription: OpenAiApi.GenerateCompanyDescription,
  ): Observable<string> {
    return this.httpClient.post(`${this.url}/company-description`, generateCompanyDescription, {
      responseType: 'text',
    });
  }
}
