import { AssessmentApi, RecruitmentProcessApi, TranslocoApi } from '@web/shared/data-access/model';
import { DropdownOption } from '@web/web/shared/ui/dropdown';
import { InfoCard } from '@web/web/shared/ui/info-card';
import { cloneDeep } from 'lodash';

export function getRecruitmentTypes(): DropdownOption[] {
  return [
    {
      label: RecruitmentProcessApi.recruitmentStepTypeToFormattedText(RecruitmentProcessApi.RecruitmentStepType.CUSTOM),
      value: RecruitmentProcessApi.RecruitmentStepType.CUSTOM,
    },
    {
      value: RecruitmentProcessApi.RecruitmentStepType.PICK,
      label: RecruitmentProcessApi.recruitmentStepTypeToFormattedText(RecruitmentProcessApi.RecruitmentStepType.PICK),
    },
    {
      value: RecruitmentProcessApi.RecruitmentStepType.POTENTIAL_PICK,
      label: RecruitmentProcessApi.recruitmentStepTypeToFormattedText(
        RecruitmentProcessApi.RecruitmentStepType.POTENTIAL_PICK,
      ),
    },
    {
      value: RecruitmentProcessApi.RecruitmentStepType.ASSESSMENT,
      label: RecruitmentProcessApi.recruitmentStepTypeToFormattedText(
        RecruitmentProcessApi.RecruitmentStepType.ASSESSMENT,
      ),
    },
  ];
}

export function convertToEditableAssessment(
  assessments: AssessmentApi.AssessmentDetails[],
): AssessmentApi.Assessment[] {
  return assessments.map(assessment => ({
    title: assessment.title,
    score: assessment.score,
    id: assessment.id,
    isHidden: false,
    syncActive: false,
    positionIndex: -1,
    recruitmentProcessType: RecruitmentProcessApi.RecruitmentStepType.ASSESSMENT,
    hasPassScore: assessment.hasPassScore ?? false,
    questions: assessment.questions?.map(q => ({
      id: q.id,
      hasMultipleAnswers: q.hasMultipleAnswers,
      assessmentId: assessment.id,
      nameTextVariants: q.nameVariants.map(nv => ({
        id: nv.id,
        value: nv.value,
        text: nv.locale.toUpperCase(),
        emoji: TranslocoApi.getEmoji(nv.locale),
        locale: nv.locale,
      })),
      answers: q.answers.map(a => ({
        id: a.id,
        score: parseInt(a.score + ''),
        questionId: q.id,
        answer: '',
        answerNameTextVariants: a.answerVariants.map(atv => ({
          id: atv.id,
          value: atv.value,
          text: atv.locale.toUpperCase(),
          emoji: TranslocoApi.getEmoji(atv.locale),
          locale: atv.locale,
        })),
      })),
    })),
  }));
}

export function convertStepsToInfoCard(steps: RecruitmentProcessApi.RecruitmentStep[]): InfoCard[] {
  return steps.map(step => {
    const clonedStep = cloneDeep(step);

    return {
      title: RecruitmentProcessApi.defaultRecruitmentStepTypeToTranslocoString(
        <RecruitmentProcessApi.RecruitmentStepType>clonedStep.title,
      ).length
        ? RecruitmentProcessApi.defaultRecruitmentStepTypeToTranslocoString(
            <RecruitmentProcessApi.RecruitmentStepType>clonedStep.title,
          )
        : clonedStep.title,
      isHidden: clonedStep.isHidden,
      syncActive: !!clonedStep?.externalJobId,
      hasDeleteBtn:
        clonedStep.recruitmentProcessType !== RecruitmentProcessApi.RecruitmentStepType.APPLICATION &&
        clonedStep.recruitmentProcessType !== RecruitmentProcessApi.RecruitmentStepType.HIRE &&
        clonedStep.recruitmentProcessType !== RecruitmentProcessApi.RecruitmentStepType.NO_HIRE,
      hasEditBtn:
        clonedStep.recruitmentProcessType !== RecruitmentProcessApi.RecruitmentStepType.HIRE &&
        clonedStep.recruitmentProcessType !== RecruitmentProcessApi.RecruitmentStepType.NO_HIRE,
      checkBoxTitle: 'Hide',
    };
  });
}

export function insertNewRecruitmentProcessStep(
  steps: RecruitmentProcessApi.RecruitmentStep[],
  newStep: RecruitmentProcessApi.RecruitmentStep,
): RecruitmentProcessApi.RecruitmentStep[] {
  let updatedSteps = cloneDeep(steps).map(step => {
    if (step.positionIndex >= newStep.positionIndex) {
      return { ...step, positionIndex: step.positionIndex + 1 };
    }

    return step;
  });

  updatedSteps.push(newStep);

  updatedSteps = updatedSteps.sort((a, b) => a.positionIndex - b.positionIndex);

  return updatedSteps.map((step, index) => ({
    ...step,
    positionIndex: index,
  }));
}

export function normalizePositionIndexes(
  steps: RecruitmentProcessApi.RecruitmentStep[],
): RecruitmentProcessApi.RecruitmentStep[] {
  return cloneDeep(steps).map((step, index) => ({
    ...step,
    positionIndex: index,
  }));
}
