import { createAction, props } from '@ngrx/store';
import { ApplicantTrackingSystemApi, AssessmentApi, JobApplicationApi } from '@web/shared/data-access/model';

enum ActionType {
  FETCH_APPLICATIONS = '[Job Application] Fetch all applications',
  SET_APPLICATION = '[Job Application] Set applications',
  REMOVE_FROM_STORE = '[Job Application] Remove from store',
  CLEAR_DATA_FROM_STORE = '[Job Application] Clear data from store',
  FETCH_ASSESSMENT = '[Job Application] Fetch assessment for specific application',
  SET_ASSESSMENT = '[Job Application] Set assessment for specific application',
  SET_LOADING = '[Job Application] Set loading state',
  ENABLE_2ND_CHANCE_ASSESSMENT = '[Job Application] Enable 2nd chance assessment',
  SEND_REMAINDER_EMAIL = '[Job Application] Send remainder email',
  UPDATE_ASSESSMENT_STATUS = '[Job Application] Update assessment status',
  TOGGLE_APPLICATION_VISIBILITY = '[Job Application] Toggle application visibility',
  SET_APPLICATION_VISIBILITY = '[Job Application] Set application visibility',
  DELETE_APPLICATION = '[Job Application] Delete application',
  UPDATE_APPLICATION_PHASE = '[Job Application] Update application phase',
  ABORT_APPLICATION = '[Job Application] Abort application',
}

export const setJobDetailApplications = createAction(
  ActionType.SET_APPLICATION,
  props<{ applications: ApplicantTrackingSystemApi.Application[] }>(),
);

export const fetchAllApplications = createAction(ActionType.FETCH_APPLICATIONS);

export const fetchAssessment = createAction(ActionType.FETCH_ASSESSMENT, props<{ applicantId: string }>());

export const setAssessment = createAction(
  ActionType.SET_ASSESSMENT,
  props<{
    assessment: AssessmentApi.ApplicantParticipation[];
  }>(),
);

export const setLoadingState = createAction(ActionType.SET_LOADING, props<{ isLoading: boolean }>());

export const enable2ndChanceAssessment = createAction(
  ActionType.ENABLE_2ND_CHANCE_ASSESSMENT,
  props<{
    assessmentId: string;
    applicantId: string;
  }>(),
);

export const sendRemainderEmail = createAction(
  ActionType.SEND_REMAINDER_EMAIL,
  props<{
    assessmentId: string;
    applicantId: string;
  }>(),
);

export const updateAssessmentStatus = createAction(
  ActionType.UPDATE_ASSESSMENT_STATUS,
  props<{
    applicantId: string;
    participationId: string;
    status: AssessmentApi.AssessmentParticipationStatus;
  }>(),
);

export const toggleApplicationVisibility = createAction(
  ActionType.TOGGLE_APPLICATION_VISIBILITY,
  props<{
    application: ApplicantTrackingSystemApi.Application;
  }>(),
);

export const setApplicationVisibility = createAction(
  ActionType.SET_APPLICATION_VISIBILITY,
  props<{
    applicationId: string;
    isHidden: boolean;
  }>(),
);

export const updateApplicationPhase = createAction(
  ActionType.UPDATE_APPLICATION_PHASE,
  props<{
    application: ApplicantTrackingSystemApi.Application;
    skipCommunication: boolean;
  }>(),
);

export const abortApplication = createAction(
  ActionType.ABORT_APPLICATION,
  props<{
    abortApplication: JobApplicationApi.AbortApplication;
  }>(),
);

export const deleteApplication = createAction(ActionType.DELETE_APPLICATION, props<{ id: string }>());

export const removeFromStore = createAction(ActionType.REMOVE_FROM_STORE, props<{ id: string }>());

export const clearApplicationDataFromStore = createAction(ActionType.CLEAR_DATA_FROM_STORE);
