import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { JobApi } from '@web/shared/data-access/model';

@Pipe({
  name: 'headerImg',
  standalone: true,
  pure: false,
})
export class HeaderImgPipe implements PipeTransform {
  public transform(jobBranding: JobApi.JobDetailBranding | undefined): string | undefined {
    if (!jobBranding) return undefined;

    return jobBranding.images.find(i => i.isHeader)?.previewUrl.toString();
  }
}
