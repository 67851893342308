import { HereMapsApi } from './here-maps.namespace';

export namespace ApplicantEducationApi {
  export interface Education {
    id?: string;
    title?: string;
    address: HereMapsApi.AddressData;
    name: string;
    fromDate: Date;
    toDate?: Date;
    degree?: Degree;
  }

  export enum Degree {
    HIGH_SCHOOL = 'HIGH_SCHOOL',
    MASTER = 'MASTER',
    BACHELOR = 'BACHELOR',
    DIPLOMA = 'DIPLOMA',
    DOCTORATE = 'DOCTORATE',
    PROFESSORSHIP = 'PROFESSORSHIP',
    MAGISTER_DEGREE = 'MAGISTER_DEGREE',
    BASIC_SCHOOL_CERTIFICATE = 'BASIC_SCHOOL_CERTIFICATE',
    UNIVERSITY_OF_APPLIED_SCIENCES_ENTRANCE = 'UNIVERSITY_OF_APPLIED_SCIENCES_ENTRANCE',
    SECONDARY_SCHOOL_CERTIFICATE = 'SECONDARY_SCHOOL_CERTIFICATE',
  }
}
