import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, fromEvent } from 'rxjs';
import { ScreenSizeDf, ScreenVariantDf } from './screen-size-tracker.interface';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeTrackerService {
  public screenWidth: number;
  public screenHeight: number;

  public resizeObservable$: Observable<Event>;
  public dfScreenSize$: BehaviorSubject<{ currentScreenWidth: number; screen: ScreenSizeDf }>;

  private get screenSizesDf(): ScreenVariantDf {
    return {
      mobileSmall: Number(this.getPropertyFromDom('--df-max-screen-mobile-small')),
      mobile: Number(this.getPropertyFromDom('--df-max-screen-mobile')),
      tabletPortrait: Number(this.getPropertyFromDom('--df-max-screen-tablet-portrait')),
      tabletLandscape: Number(this.getPropertyFromDom('--df-max-screen-tablet-landscape')),
      desktop: Number(this.getPropertyFromDom('--df-max-screen-desktop')),
      desktopLarge: Number(this.getPropertyFromDom('--df-min-screen-desktop-large')),
    };
  }

  constructor() {
    this.init();
  }

  private getPropertyFromDom(prop: string): string {
    return window.getComputedStyle(document.documentElement).getPropertyValue(prop);
  }

  private init(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.resizeObservable$ = fromEvent(window, 'resize').pipe(distinctUntilChanged());
    this.dfScreenSize$ = new BehaviorSubject({ screen: this.getScreenSizeDf(), currentScreenWidth: this.screenWidth });

    this.resizeObservable$.subscribe(() => {
      this.screenWidth = window.innerWidth;

      this.screenHeight = window.innerHeight;
      this.dfScreenSize$.next({ screen: this.getScreenSizeDf(), currentScreenWidth: this.screenWidth });
    });
  }

  private getScreenSizeDf(): ScreenSizeDf {
    const { mobileSmall, mobile, desktop, tabletLandscape, tabletPortrait } = this.screenSizesDf;

    if (this.screenWidth < mobileSmall) {
      return ScreenSizeDf.mobileSmall;
    }

    if (this.screenWidth < mobile) {
      return ScreenSizeDf.mobile;
    }

    if (this.screenWidth < tabletPortrait) {
      return ScreenSizeDf.tabletPortrait;
    }

    if (this.screenWidth < tabletLandscape) {
      return ScreenSizeDf.tabletLandscape;
    }

    if (this.screenWidth < desktop) {
      return ScreenSizeDf.desktop;
    }

    return ScreenSizeDf.desktopLarge;
  }
}
