import { createAction, props } from '@ngrx/store';
import { JobApi, TextVariantApi, TranslocoApi } from '@web/shared/data-access/model';

enum ActionType {
  SET_BRANDING_DATA = '[Branding] Set job branding data',
  FILL_WRITE_DATA = '[Branding] Fill in write data',
  WRITE_BRANDING_DATA = '[Branding] Write in branding data',
  RESET_BRANDING_DATA = '[Branding] Reset write data',
  DELETE_IMAGE = '[Branding] Delete image',
  GENERATE_DESCRIPTION = '[Branding] Generate description',
  GENERATE_DESCRIPTION_TRANSLATIONS = '[Branding] Generate description translations',
  SET_GENERATED_DESCRIPTION_PREVIEW = '[Branding] Set Generated description preview',
  INSERT_GENERATED_DESCRIPTION = '[Branding] Insert Generated description',
  SET_GENERATED_DESCRIPTION_SHORT_PREVIEW = '[Branding] Set Generated short description preview',
  INSERT_GENERATED_DESCRIPTION_SHORT = '[Branding] Insert Generated short description preview',
  SET_DESCRIPTION_ACTIVE_LOCALE = '[Branding] Set job description locale',
  SET_SHORT_DESCRIPTION_ACTIVE_LOCALE = '[Branding] Set short job description locale',
  CLEAR_DESCRIPTION_PREVIEW = '[Branding] Clear generated description preview',
  SET_TRANSLATED_JOB_DESCRIPTION_TEXT_VARIANTS = '[Branding] Set translated job description text variants',
  SET_TRANSLATED_SHORT_JOB_DESCRIPTION_TEXT_VARIANTS = '[Branding] Set translated short job description text variants',
  TOGGLE_LOADING_AI_ASSISTANT = '[Job Detail] Toggle AI Assistant loading',
}

export const setJobDetailBranding = createAction(
  ActionType.SET_BRANDING_DATA,
  props<{ branding: JobApi.JobDetailBranding }>(),
);

export const fillBrandingWriteData = createAction(ActionType.FILL_WRITE_DATA);

export const writeBrandingData = createAction(
  ActionType.WRITE_BRANDING_DATA,
  props<{ branding: JobApi.JobDetailBranding }>(),
);

export const deleteImage = createAction(ActionType.DELETE_IMAGE, props<{ id: string }>());

export const generateJobDescription = createAction(
  ActionType.GENERATE_DESCRIPTION_TRANSLATIONS,
  props<{ descriptionType: JobApi.DescriptionType }>(),
);

export const generateTranslations = createAction(
  ActionType.GENERATE_DESCRIPTION,
  props<{ descriptionType: JobApi.DescriptionType }>(),
);

export const setGeneratedJobDescriptionPreview = createAction(
  ActionType.SET_GENERATED_DESCRIPTION_PREVIEW,
  props<{ generatedDescription: string }>(),
);

export const setTranslatedJobDescriptionTextVariants = createAction(
  ActionType.SET_TRANSLATED_JOB_DESCRIPTION_TEXT_VARIANTS,
  props<{ textVariants: TextVariantApi.CreateTextVariant[] }>(),
);

export const setTranslatedShortJobDescriptionTextVariants = createAction(
  ActionType.SET_TRANSLATED_SHORT_JOB_DESCRIPTION_TEXT_VARIANTS,
  props<{ textVariants: TextVariantApi.CreateTextVariant[] }>(),
);

export const insertGeneratedDescription = createAction(
  ActionType.INSERT_GENERATED_DESCRIPTION,
  props<{ branding: JobApi.JobDetailBranding }>(),
);

export const insertGeneratedShortDescription = createAction(
  ActionType.INSERT_GENERATED_DESCRIPTION_SHORT,
  props<{ branding: JobApi.JobDetailBranding }>(),
);

export const setGeneratedShortDescriptionPreview = createAction(
  ActionType.SET_GENERATED_DESCRIPTION_SHORT_PREVIEW,
  props<{ generatedShortDescription: string }>(),
);

export const setDescriptionActiveLanguage = createAction(
  ActionType.SET_DESCRIPTION_ACTIVE_LOCALE,
  props<{ locale: TranslocoApi.Locale }>(),
);

export const clearGeneratedDescriptionPreview = createAction(ActionType.CLEAR_DESCRIPTION_PREVIEW);

export const setShortDescriptionActiveLanguage = createAction(
  ActionType.SET_SHORT_DESCRIPTION_ACTIVE_LOCALE,
  props<{ locale: TranslocoApi.Locale }>(),
);

export const resetWriteBrandingData = createAction(ActionType.RESET_BRANDING_DATA);

export const setIsAiAssistantLoading = createAction(
  ActionType.TOGGLE_LOADING_AI_ASSISTANT,
  props<{ isLoading: boolean }>(),
);
