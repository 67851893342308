import { Injectable } from '@angular/core';
import { CompanyApi } from '@web/shared/data-access/model';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyWebsocketService {
  constructor(private readonly socket: Socket) {}

  /**
   *
   * @param companyId
   */
  public initializeConnection(companyId: string): void {
    this.socket.emit(CompanyApi.WebSocketChannelType.INITIALIZE, companyId);
  }

  /**
   *
   */
  public onStatisticsUpdate(): Observable<CompanyApi.Statistics> {
    return this.socket.fromEvent<CompanyApi.Statistics>(CompanyApi.WebSocketChannelType.STATISTICS);
  }

  /**
   *
   */
  public disconnect(): void {
    this.socket.disconnect();
  }
}
