import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { HelpViewModel } from '@web/web/employer/core/help/data-access';
import { ButtonModule } from '@web/web/shared/ui/button';
import { ModalModule } from '@web/web/shared/ui/modal';
import { TextAreaComponent } from '@web/web/shared/ui/text-area';

@Component({
  selector: 'wh-help-dialog',
  standalone: true,
  imports: [CommonModule, ModalModule, WebCoreI18nModule, ButtonModule, TextAreaComponent],
  styles: [],
  template: `
    <ng-container *ngIf="helpViewModel.vm$ | async as vm">
      <wh-s-modal
        [open]="vm.isHelpDialogOpen"
        position="absolute"
        (modalCloseAction)="helpViewModel.toggleHelpDialog()"
      >
        <ng-container class="wh-modal-title">
          <h1 class="wh-header-lg">{{ 'core.help.feature.dialog.title' | transloco }}</h1>
          <p class="wh-paragraph-md">{{ 'core.help.feature.dialog.subtitle' | transloco }}</p>
        </ng-container>

        <ng-container class="wh-modal-body">
          <wh-s-text-area
            [label]="'core.help.feature.dialog.form.label' | transloco"
            (inputChange)="setMessage($event)"
          ></wh-s-text-area>
        </ng-container>

        <ng-container class="wh-modal-actions">
          <wh-s-button
            size="lg"
            variant="zero"
            [label]="'core.help.feature.dialog.cancel' | transloco"
            (clickAction)="helpViewModel.toggleHelpDialog()"
          ></wh-s-button>

          <wh-s-button
            size="lg"
            variant="primary"
            [disabled]="!formGroup.valid"
            [label]="'core.help.feature.dialog.submit' | transloco"
            (clickAction)="sendMessage()"
          ></wh-s-button>
        </ng-container>
      </wh-s-modal>
    </ng-container>
  `,
})
export class HelpDialogComponent implements OnInit {
  public formGroup: FormGroup;
  constructor(
    public readonly helpViewModel: HelpViewModel,
    private readonly formBuilder: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      message: new FormControl('', [Validators.required]),
    });
  }

  public setMessage(message: string): void {
    this.formGroup.patchValue({ message });
  }

  public sendMessage(): void {
    const { message } = this.formGroup.value;
    this.helpViewModel.sendMessage(message);
  }
}
