import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeDf, ScreenSizeTrackerService } from '@web/web/shared/data-access/screen-size-tracker';
import { NavSideGroup, NavSideItemActionType } from '@web/web/shared/data-access/type';

@Component({
  selector: 'wh-s-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrls: ['./nav-side.component.scss'],
})
export class NavSideComponent implements OnInit {
  @Input()
  public sideNavItems: NavSideGroup[] = [];

  @Input()
  public isDrawerOpen = true;

  @Output()
  public logout = new EventEmitter<void>();

  @Output()
  public help = new EventEmitter<void>();

  @Output()
  public toggleDrawer = new EventEmitter<void | boolean>();

  public isMobileView: boolean;
  public currentScreenWidth: number;

  constructor(private readonly screenSizeTrackerService: ScreenSizeTrackerService) {}

  public actionFired(actionType: NavSideItemActionType): void {
    switch (actionType) {
      case NavSideItemActionType.LOGOUT:
        this.logout.emit();
        break;
      case NavSideItemActionType.HELP:
        this.help.emit();
        break;
    }

    if (!this.isMobileView) {
      return;
    }

    this.toggleDrawer.emit();
  }

  public ngOnInit(): void {
    this.screenSizeTrackerService.dfScreenSize$.subscribe(screenData => {
      const hasBeenDesktopView = !this.isMobileView;

      this.isMobileView =
        screenData.screen === ScreenSizeDf.mobileSmall ||
        screenData.screen === ScreenSizeDf.mobile ||
        screenData.screen === ScreenSizeDf.tabletPortrait ||
        screenData.screen === ScreenSizeDf.tabletLandscape;

      this.currentScreenWidth = screenData.currentScreenWidth;

      if (hasBeenDesktopView && this.isMobileView) {
        this.toggleDrawer.emit(false);
      }
    });
  }
}
