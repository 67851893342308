import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { AlertService } from '@web/web/shared/data-access/alert';
import { EmployerApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import * as FileSaver from 'file-saver';
import { catchError, of, take, tap } from 'rxjs';

export type ApplicationModalState = {
  hasNewApplication: boolean;
  hasPendingApplication: boolean;
  hasAnyDialogBeenOpened: boolean;
  isNewVersionModalOpen: boolean;
  isUserManagementModalOpen: boolean;
  isApplicantManagementModalOpen: boolean;
  isJobManagementModalOpen: boolean;
  isIntegrationManagementModalOpen: boolean;
  isAuthModalOpen: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ApplicationModalViewModel extends ComponentStore<ApplicationModalState> {
  public vm$ = this.select(state => ({ ...state }));

  constructor(
    private readonly jobApplicationApiService: JobApplicationApiService,
    private readonly employerApiService: EmployerApiService,
    private readonly router: Router,
    private readonly alertService: AlertService,
  ) {
    super({
      hasNewApplication: false,
      hasPendingApplication: false,
      hasAnyDialogBeenOpened: false,
      isNewVersionModalOpen: false,
      isUserManagementModalOpen: false,
      isApplicantManagementModalOpen: false,
      isJobManagementModalOpen: false,
      isIntegrationManagementModalOpen: false,
      isAuthModalOpen: false,
    });
  }

  public checkApplicationStatus(): void {
    this.jobApplicationApiService
      .checkApplicationStatus()
      .pipe(
        take(1),
        tapResponse({
          next: applicationStatus =>
            this.patchState({
              hasNewApplication: applicationStatus.hasNewApplication,
              hasPendingApplication: applicationStatus.hasPendingApplication,
            }),
          error: () =>
            this.patchState({
              hasNewApplication: false,
              hasPendingApplication: false,
            }),
        }),
      )
      .subscribe();
  }

  public closeNewApplicationModal(): void {
    this.patchState({ hasNewApplication: false, hasAnyDialogBeenOpened: true });
  }

  public closePendingApplicationModal(): void {
    this.patchState({ hasPendingApplication: false, hasAnyDialogBeenOpened: true });
  }

  public closeNewVersionModal(): void {
    this.patchState({ isNewVersionModalOpen: false });
  }

  public openUserManagementModal(): void {
    this.patchState({ isUserManagementModalOpen: true });
  }

  public closeUserManagementModal(): void {
    this.patchState({ isUserManagementModalOpen: false });
  }

  public openApplicantManagementModal(): void {
    this.patchState({ isApplicantManagementModalOpen: true });
  }

  public closeApplicantManagementModal(): void {
    this.patchState({ isApplicantManagementModalOpen: false });
  }

  public openJobManagementModal(): void {
    this.patchState({ isJobManagementModalOpen: true });
  }

  public closeJobManagementModal(): void {
    this.patchState({ isJobManagementModalOpen: false });
  }

  public openIntegrationManagementModal(): void {
    this.patchState({ isIntegrationManagementModalOpen: true });
  }

  public closeIntegrationManagementModal(): void {
    this.patchState({ isIntegrationManagementModalOpen: false });
  }

  public openAuthModal(): void {
    this.patchState({ isAuthModalOpen: true });
  }

  public closeAuthModal(): void {
    this.patchState({ isAuthModalOpen: false });
  }

  public checkIfVisitedNewVersion(): void {
    const visitedV2 = localStorage.getItem('visitedV2');

    if (visitedV2 !== 'true') {
      this.get().isNewVersionModalOpen = true;
      localStorage.setItem('visitedV2', 'true');
    }
  }

  public checkIfVisitedUserManagement(): void {
    const visitedUserManagement = localStorage.getItem('visitedUserManagement');

    if (visitedUserManagement !== 'true') {
      this.openUserManagementModal();
      localStorage.setItem('visitedUserManagement', 'true');
    }
  }

  public checkIfVisitedApplicantManagement(): void {
    const visitedApplicantManagement = localStorage.getItem('visitedApplicantManagement');

    if (visitedApplicantManagement !== 'true') {
      this.openApplicantManagementModal();
      localStorage.setItem('visitedApplicantManagement', 'true');
    }
  }

  public checkIfVisitedJobManagement(): void {
    const visitedJobManagement = localStorage.getItem('visitedJobManagement');

    if (visitedJobManagement !== 'true') {
      this.openJobManagementModal();
      localStorage.setItem('visitedJobManagement', 'true');
    }
  }

  public checkIfVisitedIntegrationManagement(): void {
    const visitedIntegrationManagement = localStorage.getItem('visitedIntegrationManagement');

    if (visitedIntegrationManagement !== 'true') {
      this.openIntegrationManagementModal();
      localStorage.setItem('visitedIntegrationManagement', 'true');
    }
  }

  public downloadV2InfoPDF(): void {
    this.employerApiService
      .getV2InfoPdf()
      .pipe(
        take(1),
        tap(res => {
          const blob = new Blob([res]);
          FileSaver.saveAs(blob, 'WorkerHero-Handbook.pdf');
          this.alertService.success('PDF file downloaded successfully.');
          this.closeNewVersionModal();
        }),
        catchError(() => {
          this.alertService.error('Error getting PDF file');

          return of({});
        }),
      )
      .subscribe();
  }

  public navigateToATSFromNewApplicationModal(): void {
    this.router.navigateByUrl(`/recruiting/ats`);

    this.closeNewApplicationModal();
  }

  public navigateToATSFromPendingApplicationModal(): void {
    this.router.navigateByUrl(`/recruiting/ats`);

    this.closePendingApplicationModal();
  }
}
