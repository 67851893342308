<wh-s-progress-bar *ngIf="loadingViewModel.isLoading$ | async"></wh-s-progress-bar>

<!-- Global alert feature, use ** alertService ** anywhere in app to show alerts -->
<wh-s-alert-container
  *ngIf="alertService.vm$ | async as vm"
  [items]="vm.items"
  (closeItem)="alertService.removeAlert($event)"
></wh-s-alert-container>

<!-- help / ticketing dialog -->
<wh-help-dialog />

<wh-s-panel-top
  [avatarSrc]="employerAuthViewModel.getLoginData()?.avatarUrl ?? ''"
  [username]="employerAuthViewModel.getLoginData()?.firstName ?? ''"
  [languages]="(languageSwitchService.vm$ | async)?.languagesNew ?? []"
  (toggleDrawer)="sideNavService.toggleDrawer()"
  (languageClicked)="switchLanguage($event)"
  (profileClick)="profileClick()"
  (logoClick)="logoClick()"
></wh-s-panel-top>

<wh-s-nav-side
  [sideNavItems]="sideNavService.navSideItems"
  [isDrawerOpen]="sideNavService.isDrawerOpen"
  (toggleDrawer)="sideNavService.toggleDrawer($event)"
  (logout)="onLogout()"
  (help)="onHelp()"
>
  <main slot="content">
    <router-outlet></router-outlet>
  </main>
</wh-s-nav-side>

<wh-s-file-viewer />

@if (applicationModalViewModel.vm$ | async; as vm) {
  <wh-s-modal
    position="absolute"
    [open]="vm.hasNewApplication"
    (modalCloseAction)="applicationModalViewModel.closeNewApplicationModal()"
  >
    <div class="wh-modal-title">
      <h4 class="wh-header-h4">{{ 'domain.init.has-new-application.title' | transloco }}</h4>
    </div>

    <div class="wh-modal-body">
      <p class="wh-paragraph-md">{{ 'domain.init.has-new-application.p-1' | transloco }}</p>

      <p class="wh-paragraph-md">{{ 'domain.init.has-new-application.p-2' | transloco }}</p>
    </div>

    <ng-container class="wh-modal-actions">
      <wh-s-button
        size="lg"
        variant="zero"
        [elevated]="false"
        [label]="'domain.init.has-new-application.cancel' | transloco"
        (clickAction)="applicationModalViewModel.closeNewApplicationModal()"
      />

      <wh-s-button
        size="lg"
        variant="primary"
        [elevated]="false"
        [label]="'domain.init.has-new-application.cta' | transloco"
        (clickAction)="applicationModalViewModel.navigateToATSFromNewApplicationModal()"
      />
    </ng-container>
  </wh-s-modal>

  <wh-s-modal
    position="absolute"
    [open]="!vm.hasNewApplication && vm.hasPendingApplication && !vm.hasAnyDialogBeenOpened"
    (modalCloseAction)="applicationModalViewModel.closePendingApplicationModal()"
  >
    <div class="wh-modal-title">
      <h4 class="wh-header-h4">{{ 'domain.init.has-pending-application.title' | transloco }}</h4>
    </div>

    <div class="wh-modal-body">
      <p class="wh-paragraph-md">{{ 'domain.init.has-pending-application.p-1' | transloco }}</p>

      <p class="wh-paragraph-md">{{ 'domain.init.has-pending-application.p-2' | transloco }}</p>
    </div>

    <ng-container class="wh-modal-actions">
      <wh-s-button
        size="lg"
        variant="zero"
        [elevated]="false"
        [label]="'domain.init.has-pending-application.cancel' | transloco"
        (clickAction)="applicationModalViewModel.closePendingApplicationModal()"
      />

      <wh-s-button
        size="lg"
        variant="primary"
        [elevated]="false"
        [label]="'domain.init.has-pending-application.cta' | transloco"
        (clickAction)="applicationModalViewModel.navigateToATSFromPendingApplicationModal()"
      />
    </ng-container>
  </wh-s-modal>

  <wh-s-modal
    position="absolute"
    [open]="vm.isNewVersionModalOpen"
    (modalCloseAction)="applicationModalViewModel.closeNewVersionModal()"
  >
    <ng-container class="wh-modal-title">
      <h1 class="wh-header-h3">{{ 'domain.init.new-app-v2-info.title' | transloco }}</h1>
    </ng-container>

    <div class="wh-modal-body">
      <p class="wh-paragraph-md">{{ 'domain.init.new-app-v2-info.p-1' | transloco }}</p>

      <p class="wh-header-h5">{{ 'domain.init.new-app-v2-info.p-2' | transloco }} 🚀</p>

      <p class="wh-paragraph-md">{{ 'domain.init.new-app-v2-info.p-3' | transloco }}</p>

      <wh-s-button
        size="lg"
        variant="primary"
        [elevated]="false"
        (clickAction)="applicationModalViewModel.downloadV2InfoPDF()"
        [label]="'domain.init.new-app-v2-info.pdf-btn-label' | transloco"
      ></wh-s-button>
    </div>
  </wh-s-modal>
}
