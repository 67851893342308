import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AlertService } from '@web/web/shared/data-access/alert';
import { EmployerApiService } from '@web/web/shared/data-access/api';
import { EMPTY, Observable, catchError, take, tap } from 'rxjs';

export interface HelpState {
  isHelpDialogOpen: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class HelpViewModel extends ComponentStore<HelpState> {
  public readonly vm$: Observable<HelpState> = this.select(state => ({
    isHelpDialogOpen: state.isHelpDialogOpen,
  }));
  constructor(
    private readonly employerApiService: EmployerApiService,
    private readonly alertService: AlertService,
  ) {
    super({ isHelpDialogOpen: false });
  }

  public toggleHelpDialog(): void {
    this.patchState({
      isHelpDialogOpen: !this.get().isHelpDialogOpen,
    });
  }

  public sendMessage(message: string): void {
    this.employerApiService
      .createHelpTicket(message)
      .pipe(
        take(1),
        tap(() => {
          this.alertService.success('Help ticket successfully created! We will get back to you soon.');
          this.toggleHelpDialog();
        }),
        catchError(() => {
          this.alertService.error('Something went wrong, please try again later.');
          this.toggleHelpDialog();

          return EMPTY;
        }),
      )
      .subscribe();
  }
}
