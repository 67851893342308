import { SafeUrl } from '@angular/platform-browser';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DropdownOption } from '@web/web/shared/ui/dropdown';
import { AssessmentApi } from './assessment.namespace';
import { BrandingApi } from './branding-api.namespace';
import { CertificateApi } from './certificate-api.namespace';
import { CompanyApi } from './company-api.namespace';
import { CompensationApi } from './compensation-api.namespace';
import { DriverLicenseApi } from './driver-license-api.namespace';
import { EducationApi } from './education-api.namespace';
import { HereMapsApi } from './here-maps.namespace';
import { IntegrationApi } from './integration.namespace';
import { JobTypeApi } from './job-type-api.namespace';
import { JobTypeCategoryApi } from './job-type-category-api.namespace';
import { JobWorkExperienceApi } from './job-work-experience-api.namespace';
import { LanguageApi } from './language-api.namespace';
import { OccupationalGroupApi } from './occupational-group-api.namespace';
import { OccupationalSubgroupApi } from './occupational-subgroup-api.namespace';
import { ProfessionalFieldApi } from './professional-fields-api.namespace';
import { RecruitmentProcessApi } from './recruitment-process-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';

export namespace JobApi {
  export enum EmploymentType {
    FULL_TIME = 'FULL_TIME',
    MINI_JOB = 'MINI_JOB',
    CONTRACTOR = 'CONTRACTOR',
    PART_TIME_20 = 'PART_TIME_20',
    PART_TIME_25 = 'PART_TIME_25',
    PART_TIME_30 = 'PART_TIME_30',
    PART_TIME_35 = 'PART_TIME_35',
    PART_TIME_37_5 = 'PART_TIME_37_5',
    SELF_EMPLOYMENT = 'SELF_EMPLOYMENT',
  }

  export enum JobStatus {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    PENDING = 'PENDING',
  }

  export enum DescriptionType {
    LONG_DESCRIPTION = 'LONG_DESCRIPTION',
    SHORT_DESCRIPTION = 'SHORT_DESCRIPTION',
  }

  export function employmentTypeToHumanReadableValue(employmentType: EmploymentType): string {
    switch (employmentType) {
      case JobApi.EmploymentType.FULL_TIME:
        return 'shared.ui.employment-type.full-time-label';
      case JobApi.EmploymentType.MINI_JOB:
        return 'shared.ui.employment-type.mini-job-label';
      case JobApi.EmploymentType.CONTRACTOR:
        return 'shared.ui.employment-type.contractor-label';
      case JobApi.EmploymentType.PART_TIME_20:
        return 'shared.ui.employment-type.part-time-20-label';
      case JobApi.EmploymentType.PART_TIME_25:
        return 'shared.ui.employment-type.part-time-25-label';
      case JobApi.EmploymentType.PART_TIME_30:
        return 'shared.ui.employment-type.part-time-30-label';
      case JobApi.EmploymentType.PART_TIME_35:
        return 'shared.ui.employment-type.part-time-35-label';
      case JobApi.EmploymentType.SELF_EMPLOYMENT:
        return 'shared.ui.employment-type.self-employment-label';
      case JobApi.EmploymentType.PART_TIME_37_5:
        return 'shared.ui.employment-type.part-time-375-label';
    }
  }

  export interface Job {
    id: string;
    title: TextVariantApi.TextVariant[];
    address: HereMapsApi.AddressData;
    company: CompanyApi.Company;
    employmentTypes: EmploymentType[];
    compensations: CompensationApi.CompensationViewItem[];
    jobType?: JobTypeApi.JobType;
    jobBranding?: JobDetailBranding;
    status: JobStatus;
    publicationDate?: Date;
    expirationDate?: Date;
  }

  export interface JobDetailDropdown {
    jobId: string;
    label?: string;
    companyName: string;
    city: string;
    textVariants: TextVariantApi.TextVariant[];
  }

  export interface JobApplicant {
    id: string;
    employer: string;
    address: HereMapsApi.AddressData;
    image?: string;
    title: TextVariantApi.TextVariant[];
    jobType: JobTypeApi.JobType;
    description: TextVariantApi.TextVariant[];
    compensations: CompensationApi.CompensationViewItem[];
    employmentTypes: EmploymentType[];
    assessment?: AssessmentApi.AssessmentDetails;
    jobHeaderImageUrl?: string;
    companyLogoUrl?: string;
  }

  export interface JobAdmin extends Job {
    companyName: string;
    recruitmentSteps: RecruitmentProcessApi.RecruitmentStep[];
  }

  export interface JobDetails {
    id: string;
    titleTextVariants: TextVariantApi.TextVariant[];
    shortDescriptionTextVariants: TextVariantApi.TextVariant[];
    descriptionTextVariants: TextVariantApi.TextVariant[];
    jobType: JobTypeApi.JobType;
    address: HereMapsApi.AddressData;
    employmentTypes: EmploymentType[];
    compensations: CompensationApi.Compensation[];
    education: EducationApi.Education[];
    languages: LanguageApi.Language[];
    workExperience: JobWorkExperienceApi.WorkExperienceDetail[];
    certificates: CertificateApi.Certificate[];
    driverLicenses: DriverLicenseApi.DriverLicenseJob[];
    recruitmentProcess: RecruitmentProcessApi.RecruitmentProcess;
    company?: CompanyApi.Company;
    availableFeature?: AvailableFeature;
    jobStatus?: JobStatus;
    assessmentDetails?: AssessmentApi.AssessmentDetails[];
    assessments?: AssessmentApi.Assessment[];
    jobBranding?: JobDetailBranding;
    jobLogo?: string;
    jobs?: ProfileDetailJob[];
    createdAt?: Date;
  }

  export interface JobRequirements {
    workExperience: JobWorkExperienceApi.WorkExperience[];
    education: EducationApi.Education[];
    driverLicense: DriverLicenseApi.DriverLicenseJob[];
    certificates: CertificateApi.Certificate[];
    languages: LanguageApi.Language[];
    jobTypesDropdown: DropdownOption[];
    jobTypes: JobTypeApi.JobType[];
  }

  export interface JobDetailsView {
    id: string;
    title: TextVariantApi.TextVariant[];
    jobType: TextVariantApi.TextVariant[];
    location: string;
    companyLogo: string;
    description: TextVariantApi.TextVariant[];
    compensations: CompensationApi.Compensation[];
    employmentTypesTransloco: string[];
    images: SafeUrl[];
    videoLink?: string;
    headerImageUrl?: SafeUrl;
    jobs?: ProfileDetailJobView[];
  }

  export interface ProfileDetailJob {
    id: string;
    titleTextVariants: TextVariantApi.TextVariant[];
    compensations: CompensationApi.Compensation[];
    jobType: JobTypeApi.JobType;
    address: HereMapsApi.AddressData;
    employmentTypes: EmploymentType[];
  }

  export interface ProfileDetailJobView {
    id: string;
    title: TextVariantApi.TextVariant[];
    compensations: CompensationApi.Compensation[];
    jobType: TextVariantApi.TextVariant[];
    address: string;
    employmentTypes: EmploymentType[];
  }

  export interface CreateJob {
    address: HereMapsApi.AddressData;
    titleTextVariants: TextVariantApi.CreateTextVariant[];
    jobTypeId: string;
    isActive: boolean;
    employmentTypes: JobApi.EmploymentType[];
    compensations: CompensationApi.Compensation[];
    jobBranding?: JobDetailBranding;
  }

  export enum AvailableFeature {
    JOB_AD = 'JOB_AD',
    RECRUITING_CAMPAIGN = 'RECRUITING_CAMPAIGN',
    TALENT_SERVICE = 'TALENT_SERVICE',
  }

  export function AvailableFeatureToReadableText(feature: AvailableFeature): string {
    switch (feature) {
      case JobApi.AvailableFeature.JOB_AD:
        return 'Job Ad';
      case JobApi.AvailableFeature.RECRUITING_CAMPAIGN:
        return 'Recruiting Campaign';
      case AvailableFeature.TALENT_SERVICE:
        return 'Talent Service';
    }
  }

  export interface CreateJobAdmin extends CreateJob {
    companyId: string;
    expirationDate: string;
    availableFeature: AvailableFeature | undefined | null;
    jobStatus: JobStatus;
  }

  export interface UpdateJobAdmin extends Partial<CreateJob> {
    id: string;
    companyId: string;
    availableFeature: AvailableFeature | undefined;
    jobStatus: JobStatus;
  }

  export interface UpdateGeneralJobData {
    id: string;
    companyId: string;
    availableFeature: AvailableFeature;
    expirationDate: string;
    jobStatus: JobStatus;
    address: HereMapsApi.AddressData;
    titleTextVariants: TextVariantApi.CreateTextVariant[];
    jobTypeId: string;
    employmentTypes: JobApi.EmploymentType[];
    compensations: CompensationApi.Compensation[];
    externalJobId: string;
  }

  export interface UpdateRecruitmentProcessJobData {
    recruitmentProcessId: string;
    updateAssessments: AssessmentApi.Assessment[];
    createAssessments: AssessmentApi.Assessment[];
    updateSteps: RecruitmentProcessApi.RecruitmentStep[];
    deletableSteps: string[];
  }

  export interface UpdateJob extends Partial<CreateJob> {
    id: string;
  }

  export interface EditJob extends Partial<CreateJob> {
    id: string;
  }

  export interface JobDropdown {
    id: string;
    jobCompany: string;
  }

  export interface JobDetailBranding {
    id?: string;
    shortDescription?: TextVariantApi.TextVariant[];
    description: TextVariantApi.TextVariant[];
    videoLink: string;
    images: BrandingApi.Image[];
  }

  export interface JobDetailBrandingUpdate {
    id: string;
    jobId: string;
    shortDescription?: TextVariantApi.TextVariant[];
    description?: TextVariantApi.TextVariant[];
    videoLink?: string;
    newImages?: { isHeader: boolean; name: string; file: File }[];
    updateImages?: { id: string; isHeader: boolean }[];
    idOfImagesToDelete?: string[];
  }

  export interface JobConfiguration {
    company: CompanyApi.Company;
    package: AvailableFeature;
    publishedOn: Date;
  }

  export interface GeneralJobDetails {
    configuration: JobConfiguration;
    general: JobDetails;
  }

  export interface JobFilter {
    address: HereMapsApi.AddressZip;
    distance: string;
    professionalFields: ProfessionalFieldApi.ProfessionalField[];
    occupationalGroups: OccupationalGroupApi.OccupationalGroup[];
    occupationalSubGroups: OccupationalSubgroupApi.OccupationalSubgroup[];
    jobTypeCategories: JobTypeCategoryApi.JobTypeCategory[];
    jobTypes: JobTypeApi.JobType[];
    status: JobStatus[];
  }

  export enum JobDetailTab {
    GENERAL = 'GENERAL',
    BRANDING = 'BRANDING',
    RECRUITMENT_PROCESS = 'RECRUITMENT_PROCESS',
    ASSESSMENT = 'ASSESSMENT',
    AUDIT_LOG = 'AUDIT_LOG',
    APPLICANT_POOL = 'APPLICANT_POOL',
    APPLICATION = 'APPLICATION',
  }

  export enum JobItemDropdownAction {
    ARCHIVE = 'ARCHIVE',
    DUPLICATE = 'DUPLICATE',
    DELETE_JOB = 'DELETE_JOB',
  }

  export enum JobItemDropdownActionEmployer {
    ARCHIVE = 'ARCHIVE',
    DUPLICATE = 'DUPLICATE',
  }

  export interface JobDetailGeneral {
    compensations: CompensationApi.Compensation[];
    jobLogo?: string;
    availableFeature: AvailableFeature;
    publicationDate: string;
    expirationDate: string;
    jobStatus: JobStatus;
    createdAt?: Date;
    titleTextVariants: TextVariantApi.TextVariant[];
    jobType: JobTypeApi.JobType;
    address: HereMapsApi.AddressData;
    employmentTypes: EmploymentType[];
    company: CompanyApi.Company;
    jobs?: ProfileDetailJob[];
    externalJobId?: string; // Kombo
    integrations?: IntegrationApi.KomboIntegration[];
  }

  export interface JobDetailRecruitmentProcessWrapper {
    assessments: AssessmentApi.AssessmentDetails[];
    recruitmentProcess: RecruitmentProcessApi.RecruitmentProcess;
  }

  export interface JobList {
    id: string;
    title: TextVariantApi.TextVariant[];
  }
}
