import { createAction, props } from '@ngrx/store';
import { AssessmentApi, JobApi, RecruitmentProcessApi } from '@web/shared/data-access/model';

enum ActionType {
  SET_RECRUITMENT_PROCESS = '[Recruitment Process] Load detail job assessment',
  FILL_WRITE_DATA = '[Recruitment Process] Fill in recruitment process write data',
  WRITE_AUTO_MOVE_STEP = '[Recruitment Process] Write auto-move step',
  WRITE_ASSESSMENT_QUESTION = '[Recruitment Process] Write assessment question',
  UPDATE_ASSESSMENT_QUESTION = '[Recruitment Process] Update assessment question',
  WRITE_PASS_SCORE = '[Recruitment Process] Write pass score of assessment',
  WRITE_AFTER_PASS_ASSESSMENT_STEP = '[Recruitment Process] Write after assessment pass step',
  WRITE_AFTER_FAIL_ASSESSMENT_STEP = '[Recruitment Process] Write after assessment fail step',
  DELETE_ASSESSMENT_QUESTION = '[Recruitment Process] Delete assessment question',
  CREATE_NEW_RECRUITMENT_PROCESS_STEP = '[Recruitment Process] Create new step',
  UPDATE_RECRUITMENT_PROCESS_STEP = '[Recruitment Process] Update step',
  DELETE_RECRUITMENT_PROCESS_STEP = '[Recruitment Process] Delete step',
  TOGGLE_AUTOMATIC_ASSESSMENT = '[Recruitment Process] Toggle automatic assessment',
  RESET_RECRUITMENT_PROCESS_STATE = '[Recruitment Process] Reset recruitment process state',
}

export const setJobDetailRecruitmentProcess = createAction(
  ActionType.SET_RECRUITMENT_PROCESS,
  props<{ recruitmentProcessWrapper: JobApi.JobDetailRecruitmentProcessWrapper }>(),
);

export const fillInRecruitmentProcessWriteData = createAction(ActionType.FILL_WRITE_DATA);

export const writeAutoMoveStep = createAction(ActionType.WRITE_AUTO_MOVE_STEP, props<{ index: number }>());

export const writeAssessmentQuestion = createAction(
  ActionType.WRITE_ASSESSMENT_QUESTION,
  props<{ assessmentId: string; question: AssessmentApi.AssessmentQuestion }>(),
);

export const updateAssessmentQuestion = createAction(
  ActionType.UPDATE_ASSESSMENT_QUESTION,
  props<{ assessmentId: string; questionToUpdate: AssessmentApi.AssessmentQuestion; index: number }>(),
);

export const writePassScore = createAction(
  ActionType.WRITE_PASS_SCORE,
  props<{ assessmentId: string; passScore: number }>(),
);

export const writeAfterPassAssessmentStep = createAction(
  ActionType.WRITE_AFTER_PASS_ASSESSMENT_STEP,
  props<{ assessmentId: string; afterPassStep: RecruitmentProcessApi.RecruitmentStep | null }>(),
);

export const writeAfterFailAssessmentStep = createAction(
  ActionType.WRITE_AFTER_FAIL_ASSESSMENT_STEP,
  props<{ assessmentId: string; afterFailStep: RecruitmentProcessApi.RecruitmentStep | null }>(),
);

export const deleteAssessmentQuestion = createAction(
  ActionType.DELETE_ASSESSMENT_QUESTION,
  props<{ assessmentId: string; questionToDelete: AssessmentApi.AssessmentQuestion }>(),
);

export const createNewRecruitmentProcessStep = createAction(
  ActionType.CREATE_NEW_RECRUITMENT_PROCESS_STEP,
  props<{ newStep: RecruitmentProcessApi.RecruitmentStep }>(),
);

export const updateRecruitmentProcessStep = createAction(
  ActionType.UPDATE_RECRUITMENT_PROCESS_STEP,
  props<{ updatedStep: RecruitmentProcessApi.RecruitmentStep }>(),
);

export const deleteRecruitmentProcessStep = createAction(
  ActionType.DELETE_RECRUITMENT_PROCESS_STEP,
  props<{ index: number }>(),
);

export const toggleAutomaticAssessment = createAction(
  ActionType.TOGGLE_AUTOMATIC_ASSESSMENT,
  props<{ assessmentId: string }>(),
);

export const resetRecruitmentProcessState = createAction(ActionType.RESET_RECRUITMENT_PROCESS_STATE);
