import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavSideGroup, NavSideItemActionType } from '@web/web/shared/data-access/type';

@Component({
  selector: 'wh-s-nav-side-group',
  templateUrl: './nav-side-group.component.html',
  styleUrls: ['./nav-side-group.component.scss'],
})
export class NavSideGroupComponent {
  @Input()
  public navSideGroup: NavSideGroup;

  @Input()
  public isLastInList = true;

  @Input()
  public isDrawerOpen = true;

  @Output()
  public actionFired = new EventEmitter<NavSideItemActionType>();
}
