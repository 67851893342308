import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmployerAuthViewModel } from '@web/web/employer/core/auth/data-access';
import { NavSideGroup, NavSideItemActionType } from '@web/web/shared/data-access/type';
import { filter, tap } from 'rxjs';
import { toggleSidenavDrawerAction } from 'web/shared/data-access/sidenav';

@Injectable({
  providedIn: 'root',
})
export class EmployerSidenavService {
  public isVisible = true;
  public firstName = '';
  public loggedInUserName = '';
  public avatarUrl = '';
  public isDrawerOpen = true;
  public companyName = '';

  constructor(
    private readonly employerAuthViewModel: EmployerAuthViewModel,
    private readonly store: Store,
  ) {
    this.employerAuthViewModel.isAuthenticated$
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        tap(() => {
          const loginData = this.employerAuthViewModel.getLoginData();

          if (!loginData) {
            return;
          }

          this.firstName = loginData.firstName;
          this.loggedInUserName = `${loginData.firstName} ${loginData.lastName.charAt(0).toUpperCase()}.`;
          this.avatarUrl = loginData.avatarUrl ?? '';
          this.companyName = loginData.companyName;
          this.navSideItems = [
            {
              title: 'shared.sidenav.home.title',
              items: [
                {
                  title: 'shared.sidenav.home.dashboard',
                  icon: 'grid',
                  url: 'dashboard',
                },
              ],
            },
            {
              title: 'shared.sidenav.recruiting.title',
              items: [
                {
                  title: 'shared.sidenav.recruiting.jobs',
                  icon: 'briefcase',
                  url: 'recruiting/jobs',
                },
                {
                  title: 'shared.sidenav.recruiting.applicants',
                  icon: 'archive',
                  url: 'recruiting/ats',
                },
              ],
            },
            {
              title: this.companyName,
              items: [
                {
                  title: 'shared.sidenav.company.profile',
                  icon: 'globe',
                  url: 'company',
                },
                {
                  title: 'shared.sidenav.company.integrations',
                  icon: 'zap',
                  url: 'integrations',
                },
                {
                  title: 'shared.sidenav.company.users',
                  icon: 'users',
                  url: 'users',
                },
              ],
            },
            {
              title: 'shared.sidenav.account.title',
              items: [
                {
                  title: 'shared.sidenav.account.profile',
                  icon: 'user',
                  url: 'profile',
                },
                {
                  title: 'shared.sidenav.account.help',
                  icon: 'help-circle',
                  actionType: NavSideItemActionType.HELP,
                },
                {
                  title: 'shared.sidenav.account.logout',
                  icon: 'log-out',
                  actionType: NavSideItemActionType.LOGOUT,
                },
              ],
            },
          ];
        }),
      )
      .subscribe();
  }

  public navSideItems: NavSideGroup[] = [];

  public toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  public toggleDrawer(isDrawerOpen?: void | boolean | undefined): void {
    if (typeof isDrawerOpen == 'boolean') {
      this.isDrawerOpen = isDrawerOpen;

      return;
    }

    this.isDrawerOpen = !this.isDrawerOpen;
    this.store.dispatch(toggleSidenavDrawerAction());
  }
}
