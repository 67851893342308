import {
  CompanyApi,
  CompensationApi,
  HereMapsApi,
  JobApi,
  JobTypeApi,
  TranslocoApi,
} from '@web/shared/data-access/model';
import { cloneDeep } from 'lodash';
import { GeneralInfo } from 'web/shared/ui/job-general-info';

export function convertReadonlyDataToWriteData(initialData: JobApi.JobDetailGeneral | null): {
  generalInfo: GeneralInfo | null;
  selectedEmploymentTypes: JobApi.EmploymentType[];
  compensationType: CompensationApi.Compensation[];
  //  Publish stuff
  jobStatus: JobApi.JobStatus | null;
  selectedCompany: CompanyApi.Company | null;
  selectedFeature: JobApi.AvailableFeature | null;
  selectedExpirationDate: string | null;
  publicationDate: string | null;
} | null {
  if (!initialData) {
    return null;
  }

  // noinspection TypeScriptValidateTypes
  return {
    compensationType: initialData?.compensations ? cloneDeep([...initialData.compensations]) : [],
    generalInfo: {
      jobTitle: initialData.titleTextVariants
        ? cloneDeep(
            ...[
              initialData.titleTextVariants.map(jtv => ({
                value: String(jtv.value),
                text: String(jtv.locale.toUpperCase()),
                emoji: String(TranslocoApi.getEmoji(jtv.locale)),
              })),
            ],
          )
        : [],
      jobType: cloneDeep({ ...initialData!.jobType }) as JobTypeApi.JobType,
      jobAddress: cloneDeep({ ...initialData!.address }) as HereMapsApi.AddressData,
    },
    jobStatus: initialData?.jobStatus,
    selectedCompany: { ...initialData?.company },
    selectedFeature: initialData?.availableFeature,
    selectedExpirationDate: initialData?.expirationDate,
    publicationDate: initialData?.publicationDate,
    selectedEmploymentTypes: initialData?.employmentTypes ? [...cloneDeep(initialData.employmentTypes)] : [],
  };
}
