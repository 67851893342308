import { Injectable } from '@angular/core';
import { EmployerApi } from '@web/shared/data-access/model';
import { VerificationViewModel } from '@web/web/core/auth/data-access';
import { EmployerApiService } from '@web/web/shared/data-access/api';

@Injectable({
  providedIn: 'root',
})
export class EmployerVerificationViewModel extends VerificationViewModel<EmployerApi.EmployerLoginResponseDto> {
  constructor(employerApiService: EmployerApiService) {
    super(employerApiService);
  }
}
