import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EmployerApi, IntegrationApi, JobApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { CompanyApiService, JobApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { concatMap, map, mergeMap, of, take, tap, withLatestFrom } from 'rxjs';
import { selectRole } from 'web/shared/data-access/global';
import * as JobDetailApplicationActions from './feature/application/application.action';
import * as JobDetailBrandingActions from './feature/branding/branding.action';
import * as JobDetailGeneralActions from './feature/general/general.action';
import * as JobDetailRecruitmentProcessActions from './feature/recruitment-process/recruitment-process.action';
import * as JobDetailActions from './job-detail.action';
import { selectJobId } from './job-detail.selector';

@Injectable()
export class JobDetailEffect {
  /**
   * Once ID is set in the ngrx store via setJobId action, this effect will fire and fetch data from backend
   *
   */
  public readonly loadDataOnSetJobId$ = createEffect(
    (actions$ = inject(Actions), store = inject(Store), jobApiService = inject(JobApiService)) => {
      return actions$.pipe(
        ofType(JobDetailActions.setJobId),
        tap(() => store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }))),
        mergeMap((action: ReturnType<typeof JobDetailActions.setJobId>) =>
          jobApiService.getJobDetailGeneral(action.jobId).pipe(
            take(1),
            concatMap(jobDetailGeneral => [
              JobDetailGeneralActions.setJobDetailGeneralData({ jobDetailGeneral }),
              JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
              JobDetailActions.setCompanyId({ companyId: jobDetailGeneral.company.id }),
              JobDetailActions.setIntegrations({
                integrations: <IntegrationApi.KomboIntegration[]>jobDetailGeneral.integrations,
              }),
              JobDetailActions.setCompanyImages({
                bannerUrl: String(jobDetailGeneral.company?.bannerUrl || ''),
                avatarUrl: String(jobDetailGeneral.company?.avatarUrl || ''),
              }),
            ]),
          ),
        ),
      );
    },
  );

  /**
   * Fetches list of all companies
   *
   */
  public readonly loadCompanies$ = createEffect(
    (actions$ = inject(Actions), store = inject(Store), companyApiService = inject(CompanyApiService)) => {
      return actions$.pipe(
        ofType(JobDetailGeneralActions.fetchAllCompanies),
        mergeMap(() =>
          companyApiService.getAll().pipe(
            take(1),
            map(companies => JobDetailGeneralActions.setCompanies({ companies })),
          ),
        ),
      );
    },
  );

  /**
   * This effect will be triggered on every tab change, once setActiveTabAction is fired, effect will check which tab is active and fetch/set its data
   *
   */
  public readonly loadDataOnTabChange$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApiService = inject(JobApiService),
      jobApplicationApiService = inject(JobApplicationApiService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailActions.setActiveTabAction),
        tap(() => store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }))),
        withLatestFrom(store.select(selectJobId)),
        mergeMap(([action, jobId]: [ReturnType<typeof JobDetailActions.setActiveTabAction>, string | null]) => {
          if (!jobId) {
            throw new Error('Job ID is invalid.');
          }

          const { activeTab } = action;

          switch (activeTab) {
            case JobApi.JobDetailTab.GENERAL:
              return jobApiService.getJobDetailGeneral(jobId).pipe(
                take(1),
                concatMap(jobDetailGeneral => [
                  JobDetailGeneralActions.setJobDetailGeneralData({ jobDetailGeneral }),
                  JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                  JobDetailActions.setCompanyImages({
                    bannerUrl: String(jobDetailGeneral.company?.bannerUrl || ''),
                    avatarUrl: String(jobDetailGeneral.company?.avatarUrl || ''),
                  }),
                ]),
              );
            case JobApi.JobDetailTab.BRANDING:
              return jobApiService.getJobDetailBranding(jobId).pipe(
                take(1),
                concatMap(jobDetailBranding => [
                  JobDetailBrandingActions.setJobDetailBranding({ branding: jobDetailBranding }),
                  JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                ]),
              );
            case JobApi.JobDetailTab.APPLICATION:
              return jobApplicationApiService.getManyByJobId(jobId).pipe(
                take(1),
                concatMap(applications => [
                  JobDetailApplicationActions.setJobDetailApplications({ applications }),
                  JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                ]),
              );
            case JobApi.JobDetailTab.RECRUITMENT_PROCESS:
            case JobApi.JobDetailTab.ASSESSMENT:
              return jobApiService.getJobDetailRecruitmentProcess(jobId).pipe(
                take(1),
                concatMap(jobDetailAssessment => [
                  JobDetailRecruitmentProcessActions.setJobDetailRecruitmentProcess({
                    recruitmentProcessWrapper: jobDetailAssessment,
                  }),
                  JobDetailActions.setIsJobDetailLoading({ isLoading: false }),
                ]),
              );
            case JobApi.JobDetailTab.AUDIT_LOG:
            case JobApi.JobDetailTab.APPLICANT_POOL:
              return of(JobDetailActions.setIsJobDetailLoading({ isLoading: false }));
            default:
              return of(JobDetailActions.setIsJobDetailLoading({ isLoading: false }));
          }
        }),
      );
    },
  );

  /**
   * Re-syncs the job to the job board
   */
  public readonly reSyncToJobBoard$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      jobApiService = inject(JobApiService),
      alertService = inject(AlertService),
    ) => {
      return actions$.pipe(
        ofType(JobDetailActions.syncToJobBoard),
        tap(() => store.dispatch(JobDetailActions.setIsJobDetailLoading({ isLoading: true }))),
        withLatestFrom(store.select(selectJobId)),
        mergeMap(([_, jobId]: [ReturnType<typeof JobDetailActions.syncToJobBoard>, string | null]) => {
          if (!jobId) {
            throw new Error('Job ID is invalid.');
          }

          return jobApiService.reSyncToJobBoard(jobId).pipe(
            take(1),
            map(() => JobDetailActions.setIsJobDetailLoading({ isLoading: false })),
            tap(() => alertService.updateSuccess()),
          );
        }),
      );
    },
  );

  /**
   * This effect will be triggered on job delete action
   *
   */

  public readonly deleteJob$ = createEffect(
    () => {
      const actions$ = inject(Actions);
      const store = inject(Store);
      const jobApiService = inject(JobApiService);
      const alertService = inject(AlertService);

      return actions$.pipe(
        ofType(JobDetailActions.deleteJob),
        withLatestFrom(store.select(selectJobId)),
        tap(([, jobId]: [unknown, string | null]) => {
          if (!jobId) {
            alertService.error('Job ID is invalid.');
          }
          jobApiService.deleteJobAdmin(jobId).subscribe();
          alertService.success('Job successfully deleted.');
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * This effect will be triggered on job duplicate action
   *
   */

  public readonly duplicateJob$ = createEffect(() => {
    const actions$ = inject(Actions);
    const store = inject(Store);
    const jobApiService = inject(JobApiService);
    const alertService = inject(AlertService);
    const router = inject(Router);

    return actions$.pipe(
      ofType(JobDetailActions.duplicateJob),
      withLatestFrom(store.select(selectJobId), store.select(selectRole)),
      mergeMap(([, jobId, role]) => {
        if (!jobId) {
          alertService.error('Job ID is invalid.');
        }

        return jobApiService.duplicateJob(jobId).pipe(
          tap(newJobId => {
            alertService.success('Job successfully duplicated.');
            const navigatePath =
              role === EmployerApi.Role.EMPLOYER || role === EmployerApi.Role.OWNER
                ? `/recruiting/jobs/${newJobId}`
                : `/jobs/${newJobId}`;
            router.navigateByUrl(navigatePath);
          }),
          map(newJobId => JobDetailActions.setJobId({ jobId: newJobId })),
        );
      }),
    );
  });

  /**
   * This effect will be triggered on archive job action
   *
   */

  public readonly archiveJob$ = createEffect(
    () => {
      const actions$ = inject(Actions);
      const store = inject(Store);
      const jobApiService = inject(JobApiService);
      const alertService = inject(AlertService);
      const router = inject(Router);

      return actions$.pipe(
        ofType(JobDetailActions.archiveJob),
        withLatestFrom(store.select(selectJobId)),
        mergeMap(([, jobId]: [unknown, string | null]) => {
          if (!jobId) {
            alertService.error('Job ID is invalid.');
          }

          return jobApiService.updateJobStatus(jobId).pipe(
            tap(() => {
              alertService.success('Job successfully archived.');
              router.navigateByUrl(`recruiting/jobs`);
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );
}
