import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AuthApi, EmployerApi } from '@web/shared/data-access/model';
import { AuthInterceptor, CLIENT_INJECTION_TOKEN } from '@web/web/core/auth/data-access';
import { EmployerAuthViewModel } from '../viewmodel/employer-auth.viewmodel';

@Injectable()
export class EmployerAuthInterceptor extends AuthInterceptor<EmployerApi.EmployerLoginResponseDto> {
  constructor(
    router: Router,
    employerAuthViewModel: EmployerAuthViewModel,
    @Inject(CLIENT_INJECTION_TOKEN)
    client: AuthApi.Client,
    translocoService: TranslocoService,
  ) {
    super(router, employerAuthViewModel, client, translocoService);
  }
}
