/**
 * Checks for object equals
 *
 * Source: https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
 * @param x
 * @param y
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectEquals(x: any, y: any): boolean {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test their constructor.

  for (const p in x) {
    // eslint-disable-next-line no-prototype-builtins
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    // eslint-disable-next-line no-prototype-builtins
    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== 'object') return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectEquals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  // eslint-disable-next-line no-prototype-builtins
  for (const p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}
