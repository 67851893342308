import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmployerLoginViewModel } from './viewmodel/employer-login.viewmodel';
import { RegisterViewModel } from './viewmodel/register.viewmodel';

@NgModule({
  imports: [CommonModule],
  providers: [EmployerLoginViewModel, RegisterViewModel],
})
export class WebEmployerCoreAuthDataAccessModule {}
