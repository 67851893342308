import { FeatherIconType } from '@web/web/shared/ui/feather-icon';

export interface NavSideItem {
  icon: FeatherIconType;
  title: string;
  url?: string;
  actionType?: NavSideItemActionType;
  hidden?: boolean;
}

export enum NavSideItemActionType {
  LOGOUT,
  HELP,
}
