import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { BackdropComponent } from '@web/web/shared/ui/backdrop';
import { FeatherIconModule } from '@web/web/shared/ui/feather-icon';
import { SpinnerComponent } from '@web/web/shared/ui/spinner';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileViewerService } from 'web/shared/data-access/file-viewer';

@Component({
  selector: 'wh-s-file-viewer',
  standalone: true,
  imports: [
    CommonModule,
    NgxDocViewerModule,
    BackdropComponent,
    SpinnerComponent,
    NgSelectModule,
    FeatherIconModule,
    TranslocoModule,
  ],
  template: `
    @if (fileViewerService.isFileViewerOpen) {
      <wh-s-backdrop (backdropClick)="fileViewerService.close()" />

      <div class="document-viewer-wrapper">
        @if (fileViewerService.imageAssetUrl) {
          <img [src]="fileViewerService.imageAssetUrl" class="image-container" alt="" />
        }

        @if (fileViewerService.fileAssetUrl) {
          @if (!hasLoadedContent) {
            <wh-s-spinner />
          }

          <ngx-doc-viewer
            #docViewerComponent
            [url]="fileViewerService.fileAssetUrl"
            [ngStyle]="{ visibility: hasLoadedContent ? 'visible' : 'hidden', height: hasLoadedContent ? '100%' : 0 }"
            (loaded)="contentLoaded()"
          />
        }

        <div class="close-button" (click)="fileViewerService.close()">
          <i-feather name="x"></i-feather>
        </div>
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 800px;
        max-height: 80vh;

        & > .document-viewer-wrapper {
          position: fixed;
          display: block;
          z-index: 10002;
          background-color: whitesmoke;
          width: 95vw;
          height: 95vh;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          overflow: hidden;

          /* Shadow-soft */
          box-shadow: 2px 2px 4px rgba(168, 168, 168, 0.25);
          border-radius: 0.5rem;

          & > wh-s-spinner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          & > .image-container {
            position: absolute;

            object-fit: contain;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            max-height: 100%;
          }

          & > .close-button {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.6rem;
            background-color: black;
            color: whitesmoke;
            cursor: pointer;

            & > i-feather {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    `,
  ],
})
export class FileViewerComponent {
  @ViewChild('docViewerComponent')
  public docViewerComponent: ElementRef;

  public readonly fileViewerService = inject(FileViewerService);

  public hasLoadedContent = false;

  public contentLoaded(): void {
    this.hasLoadedContent = true;
  }
}
