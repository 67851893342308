import { Component, Input } from '@angular/core';
import { NavSideItem } from '@web/web/shared/data-access/type';

@Component({
  selector: 'wh-s-nav-side-item',
  templateUrl: './nav-side-item.component.html',
  styleUrls: ['./nav-side-item.component.scss'],
})
export class NavSideItemComponent {
  @Input()
  public navSideItem: NavSideItem;

  @Input()
  public isDrawerOpen: boolean;
}
