import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatherIconType } from '@web/web/shared/ui/feather-icon';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LongWordTrimPipe } from '@web/web/shared/util/pipes';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'wh-s-badge',
  standalone: true,
  imports: [CommonModule, FeatherModule, LongWordTrimPipe],
  template: ` <div ngClass="{{ type + ' ' + size }}" [class.active]="isActive" (click)="badgeClick.emit(text)">
    <span *ngIf="emoji && !icon">{{ emoji }}</span>

    <i-feather *ngIf="icon && !emoji" [name]="icon"></i-feather>

    <span class="text">{{ text | longWordTrim }}</span>
  </div>`,
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input()
  public emoji?: string;

  @Input()
  public icon?: FeatherIconType;

  @Input()
  public text: string;

  @Input()
  public isActive?: boolean;

  @Input()
  public type: 'grey' | 'black' | 'blue' | 'yellow' | 'red' | 'green' | 'transparent';

  @Input()
  public size: 'xs' | 'sm' | 'lg';

  @Output()
  public badgeClick: EventEmitter<string> = new EventEmitter<string>();
}
