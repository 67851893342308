// eslint-disable-next-line
export function createDownloadableLink(blob: any) {
  // Create a temporary anchor element to trigger the file download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  // Generate a timestamp string in the format dd_mm_yyyy_hh:mm:ss
  const now = new Date();
  const pad = (num: number): string => num.toString().padStart(2, '0');
  const timestamp = `${pad(now.getDate())}_${pad(now.getMonth() + 1)}_${now.getFullYear()}_${pad(now.getHours())}-${pad(now.getMinutes())}-${pad(now.getSeconds())}`;

  // Set the filename for the downloaded file
  link.download = `WorkerHero-application_${timestamp}.csv`;
  link.click();
  // Clean up
  window.URL.revokeObjectURL(link.href);
  link.remove();
}
